import { _teams } from "./_teams";
const XLSX = require("xlsx");

export const exports = {
  async exportToExcel(name, pageType) {
    if (!pageType) {
      pageType = "impediments";
    }
    let allImpediments = [];
    let allKTLO = [];
    //get impediments
    let impedimentList = await _teams.getTeamsAdminView(true, pageType);

    if (impedimentList) {
      let compList = impedimentList[0].competencies[0].questions;
      let ktloList = impedimentList[0].ktlo;
      let filtered = impedimentList;
      let sortType = localStorage.getItem("Sort Type");

      let hideIncomplete =
        localStorage.getItem("HideIncomplete") !== false ? true : false;

      if (hideIncomplete) {
        filtered = filtered.filter((item) => {
          return item.assessment_status == "Finished";
        });
      }

      let hideUnclaimed = true;
      if (hideUnclaimed) {
        filtered = filtered.filter((item) => {
          return (
            item.TeamObj[0] &&
            item.TeamObj[0].Claimed &&
            item.TeamObj[0].Claimed == true
          );
        });
      }
      filtered = filtered.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });
      impedimentList = filtered;
      //sort impedimentList by team
      impedimentList.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });

      const wb = XLSX.utils.book_new();

      let flatData = [];
      impedimentList.forEach((item) => {
        let teamImpediemnts = [];
        let teamKTLO = [];
        let flatItem = {};
        flatItem.Team = item.Team;
        flatItem.Domain = item.TeamObj[0]?.Domain;
        flatItem["Total Impediments"] = item.impediments;
        flatItem.Burden = item.impedimentScores && item.impedimentScores.burden;
        flatItem.Effort = item.impedimentScores && item.impedimentScores.effort;
        flatItem.KTLOBurden = item.ktloScores && item.ktloScores.burden;
        flatItem.KTLOEffort = item.ktloScores && item.ktloScores.effort;
        flatItem.Maturity = item.scores?.avg_score.toFixed(2);
        flatItem["Maturity Level"] = item.scores?.maturity_level;
        item.scores?.scores_by_area_obj.forEach((score) => {
          flatItem[score.name] = score.score;
          // flatItem[score.name + " Burden"] = score.burden;
          // flatItem[score.name + " Effort"] = score.effort;
        });
        flatData.push(flatItem);

        // BEGIN: be15d9bcejpp
        let list = [];
        if (pageType === "impediments") {
          list = item.impediment_list;
        } else if (pageType === "ktlo") {
          list = item.ktlo;
        }
        list &&
          list.length > 0 &&
          list.forEach((impediment) => {
            let competency = impediment.Competency
              ? compList.filter((item) => item._id == impediment.Competency)
              : null;

            let area = competency && competency !== null && competency[0].Area;
            let competencyName = competency && competency[0].Name;
            let compKey = competency && competency[0].key;
            let teamImpediment = {};
            teamImpediment.Key = "IM" + impediment.Index.toString();
            teamImpediment.Team = item.Team;

            teamImpediment.Domain = item.TeamObj[0]?.Domain;
            teamImpediment["Competency Key"] = compKey;
            teamImpediment.Area = area;
            teamImpediment.Competency = competencyName;

            teamImpediment.Title = impediment.Title;
            teamImpediment.Description = impediment.Description;
            let claimedByObject = impediment.teams_impacted.filter(
              (team) => team.claimed_by_team == item._id
            );

            teamImpediment.Burden =
              claimedByObject &&
              claimedByObject.length > 0 &&
              claimedByObject[0].score;

            teamImpediment.Effort =
              claimedByObject && claimedByObject[0].effort;

            teamImpediment.Resolved =
              claimedByObject && claimedByObject[0].Resolved;
            teamImpediment.Escalated = item.Escalated;
            teamImpediment.Category = impediment.Category;
            teamImpediment.Subcategory = impediment.Subcategory;
            teamImpediment.Scope = impediment.Scope;
            // teamImpediment.resolved = impediment.Resolved;
            teamImpediemnts.push(teamImpediment);
            allImpediments.push(teamImpediment);
          });
        // END: be15d9bcejpp
        // BEGIN: KTLO export
        // item.ktlo &&
        //   item.ktlo.length > 0 &&
        //   item.ktlo.forEach((impediment) => {
        //     let competency = impediment.Competency
        //       ? compList.filter((item) => item._id == impediment.Competency)
        //       : null;

        //     let area = competency && competency !== null && competency[0].Area;
        //     let competencyName = competency && competency[0].Name;
        //     let compKey = competency && competency[0].key;
        //     let teamImpediment = {};
        //     teamImpediment.Key = "IM" + impediment.Index.toString();
        //     teamImpediment.Team = item.Team;

        //     teamImpediment.Domain = item.TeamObj[0]?.Domain;
        //     teamImpediment["Competency Key"] = compKey;
        //     teamImpediment.Area = area;
        //     teamImpediment.Competency = competencyName;

        //     teamImpediment.Title = impediment.Title;
        //     teamImpediment.Description = impediment.Description;
        //     let claimedByObject = impediment.teams_impacted.filter(
        //       (team) => team.claimed_by_team == item._id
        //     );

        //     teamImpediment.Burden =
        //       claimedByObject &&
        //       claimedByObject.length > 0 &&
        //       claimedByObject[0].score;

        //     teamImpediment.Effort =
        //       claimedByObject && claimedByObject[0].effort;

        //     teamImpediment.Resolved =
        //       claimedByObject && claimedByObject[0].Resolved;
        //     teamImpediment.Escalated = item.Escalated;
        //     teamImpediment.Category = impediment.Category;
        //     teamImpediment.Subcategory = impediment.Subcategory;
        //     teamImpediment.Scope = impediment.Scope;
        //     // teamImpediment.resolved = impediment.Resolved;
        //     // teamImpediemnts.push(teamImpediment);
        //     teamKTLO.push(teamImpediment);
        //     allKTLO.push(teamImpediment);
        //   });
        // END KTLO export
        // before moving to the next item, create a new sheet with the impediments for this team
        const ws1 = XLSX.utils.json_to_sheet(teamImpediemnts);
        //let sheetname truncate at 31 characters
        let sheetname = item.Team;
        if (sheetname.length > 31) {
          sheetname = sheetname.substring(0, 31);
        }
        XLSX.utils.book_append_sheet(wb, ws1, sheetname);
      });

      //create new sheet for all impediments
      const ws2 = XLSX.utils.json_to_sheet(allImpediments);
      XLSX.utils.book_append_sheet(wb, ws2, `All ${pageType}`);

      // const ws3 = XLSX.utils.json_to_sheet(allKTLO);
      // XLSX.utils.book_append_sheet(wb, ws3, "All KTLO");

      const ws = XLSX.utils.json_to_sheet(flatData);
      //make this the first sheet
      XLSX.utils.book_append_sheet(wb, ws, "Summary");
      // make "Summary" the first sheet
      wb.SheetNames.unshift(wb.SheetNames.pop());
      wb.SheetNames.unshift(wb.SheetNames.pop());

      XLSX.writeFile(wb, name + ".xlsx");
    } else {
    }
  },
};
