import React, { useState } from "react";
import { useEffect, useMemo } from "react";
import { useRef } from "react";

import { useHistory } from "react-router-dom";
import MultiSelectListBox from "../components/MultiSelectListBox";
import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../components/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import {
  CheckIcon,
  ChevronUpDownIcon,
  DocumentPlusIcon,
  ShoppingCartIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { _impediments } from "../modules/impediments";
import { _competencies } from "../modules/competencies";
import { _teams } from "../modules/_teams";
import {
  ArrowDownCircleIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "../components/Tooltip";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import ModalBasic from "../components/ModalBasic";
import RightActionArea from "../components/ActionAreaRight";
import { ToggleButtons } from "../components/ReactTable";
import { _auth } from "../modules/_auth";
import { Icon } from "@iconify/react";
import Stats from "../components/Stats";
import ButtonGroup from "../components/ButtonGroup";
import AdminStats from "../components/AdminStats";
import Skeleton from "../components/Skeleton";
import DropdownFilters from "../components/DropdownFilters";
import Toggle from "../components/Toggle";
import { isShorthandPropertyAssignment } from "typescript";
import Tip from "../components/Tip";
import { sort } from "../modules/_sort";
import { exports } from "../modules/_exportToExcel";
const XLSX = require("xlsx");

export function RenderStat(value, measure) {
  return (
    <div className="flex items-center justify-center text-slate-800">
      <span>{value}</span>
    </div>
    // <div className="flex items-center py-0">
    //   <header className="p-6">
    //     {/* <h3 className="text-sm font-semibold text-slate-500 uppercase mb-1">
    //       {props.column.id}
    //     </h3> */}
    //     <div className="text-2xl capitalize font-bold text-slate-800 mb-1">
    //       {value ? value : 0}
    //     </div>
    //     <div className="text-sm">
    //       <span
    //         // className={`font-medium ${getLevelColor(item.score)}`}
    //         className=" text-slate-400"
    //       >
    //         {measure}
    //       </span>
    //     </div>
    //   </header>
    // </div>
    // <div className="flex items-center justify-center text-slate-800">
    //   <span>{props.row.original[props.column.id]}</span>
    // </div>
  );
}

function Teams() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [competencyModalOpen, setCompetencyModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportCompetenciesLoading, setExportCompetenciesLoading] =
    useState(false);
  const [exportImpedimentsLoading, setExportImpedimentsLoading] =
    useState(false);
  const [list, setList] = useState([]);
  const [competencyList, setCompetencyList] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [loadingClaimButton, setLoadingClaimButton] = useState({});
  const [changed, setChanged] = useState();
  const [edit, setEdit] = useState(true);
  const [editRow, setEditRow] = useState({});
  const [description, setDescription] = useState([]);
  const [updateField, setUpdateField] = useState([]);
  const [updatedId, setUpdatedId] = useState("");
  const [statsData, setStatsData] = useState("");

  // Filter functions!
  const [filters, setFilters] = useState(
    localStorage.getItem("Filters") == null || undefined || "" || []
      ? []
      : localStorage.getItem("Filters")
  );
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [defaultFilters, setDefaultFilters] = useState([]);
  //end filter functions

  // const [toggleValue, setToggleValue] = useState(
  //   localStorage.getItem("Team Table View") === "true" ? true : false
  // );

  const [hideIncomplete, setHideIncomplete] = useState(
    localStorage.getItem("HideIncomplete") == null || undefined || ""
      ? true
      : localStorage.getItem("HideIncomplete") == "true"
      ? true
      : false
  );
  const [hideUnclaimed, setHideUnclaimed] = useState(
    localStorage.getItem("HideUnclaimed") == null || undefined || ""
      ? false
      : localStorage.getItem("HideUnclaimed") == "true"
      ? true
      : false
  );
  const [onlyShowGate, setOnlyShowGate] = useState(false);

  const [adminStatsData, setAdminStatsData] = useState("loading");

  const [compactView, setCompactView] = useState(
    localStorage.getItem("CompactView") == null || undefined || ""
      ? false
      : localStorage.getItem("CompactView") == "true"
      ? true
      : false
  );
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [sortType, setSortType] = useState(
    localStorage.getItem("Sort Type") !== null &&
      localStorage.getItem("Sort Type") !== undefined
      ? localStorage.getItem("Sort Type")
      : "name-a-z"
  );
  const history = useHistory();
  const profile = _auth.getUserProfile();
  // const [columns, setColumns] = useState([]);

  useEffect(() => {
    (async () => {
      let impedimentList = [];
      setLoading(true);
      impedimentList = await _teams.getTeamsAdminView(null, "impediments");

      if (impedimentList) {
        let filtered = impedimentList;
        let sortType = localStorage.getItem("Sort Type");

        let hideIncomplete =
          localStorage.getItem("HideIncomplete") !== false ? true : false;
        hideIncomplete == true && setHideIncomplete(true);

        if (hideIncomplete) {
          filtered = filtered.filter((item) => {
            return item.assessment_status == "Finished";
          });
        }

        let hideUnclaimed = true;
        if (hideUnclaimed) {
          filtered = filtered.filter((item) => {
            return (
              item.TeamObj[0] &&
              item.TeamObj[0].Claimed &&
              item.TeamObj[0].Claimed == true
            );
          });
        }
        filtered = filtered && filtered.length > 0 && sortData(filtered);

        setEmployees(filtered);
        setSortType(sortType);

        setInitialValues(impedimentList);
        setList(impedimentList);
        setLoading(false);
      }
    })();
  }, [changed]);

  const exportCompetenciesToExcel = async (data, name) => {
    setExportCompetenciesLoading(true);

    if (employees) {
      let filtered = employees;
      let sortType = localStorage.getItem("Sort Type");

      let hideIncomplete =
        localStorage.getItem("HideIncomplete") !== false ? true : false;
      hideIncomplete == true && setHideIncomplete(true);

      if (hideIncomplete) {
        filtered = filtered.filter((item) => {
          return item.assessment_status == "Finished";
        });
      }

      let hideUnclaimed = true;
      if (hideUnclaimed) {
        filtered = filtered.filter((item) => {
          return (
            item.TeamObj[0] &&
            item.TeamObj[0].Claimed &&
            item.TeamObj[0].Claimed == true
          );
        });
      }
      // filtered = filtered && filtered.length > 0 && sortData(filtered);
      data = filtered;
      //sort impedimentList by team
      data.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });

      const wb = XLSX.utils.book_new();

      let flatData = [];
      data.forEach((item) => {
        let teamImpediemnts = [];

        let flatItem = {};
        flatItem.Team = item.Team;
        flatItem.Domain = item.TeamObj[0]?.Domain;
        flatItem["Total Impediments"] = item.impediments;
        flatItem.Burden = item.impedimentScores && item.impedimentScores.burden;
        flatItem.Effort = item.impedimentScores && item.impedimentScores.effort;
        flatItem.Maturity = item.scores?.avg_score.toFixed(2);
        flatItem["Maturity Level"] = item.scores?.maturity_level;
        item.scores?.scores_by_area_obj.forEach((score) => {
          flatItem[score.name] = score.score;
          // flatItem[score.name + " Burden"] = score.burden;
          // flatItem[score.name + " Effort"] = score.effort;
        });
        flatData.push(flatItem);

        // BEGIN: be15d9bcejpp

        let questions =
          item.competencies &&
          item.competencies.length > 0 &&
          item.competencies[0]?.questions;

        questions &&
          questions.length > 0 &&
          questions.forEach((impediment) => {
            //get the score by filtering teams_impacted by claimed_by_team == item._id
            let teamImpediment = {};
            teamImpediment.Key = impediment.key;
            teamImpediment.Team = item.Team;
            teamImpediment.Domain = item.TeamObj[0]?.Domain;
            teamImpediment.Competency = impediment.Name;
            teamImpediment["DevOps Area"] = impediment.Area;
            teamImpediment.Maturity = impediment.score;

            teamImpediemnts.push(teamImpediment);
          });
        // END: be15d9bcejpp
        // before moving to the next item, create a new sheet with the impediments for this team
        const ws1 = XLSX.utils.json_to_sheet(teamImpediemnts);
        //let sheetname truncate at 31 characters
        let sheetname = item.Team;
        if (sheetname.length > 31) {
          sheetname = sheetname.substring(0, 31);
        }
        XLSX.utils.book_append_sheet(wb, ws1, sheetname);
      });
      const singleTable = buildExcelObject(data);

      const singleWs = XLSX.utils.json_to_sheet(singleTable);
      //make this the second sheet

      XLSX.utils.book_append_sheet(wb, singleWs, "Comp Overview");
      const ws = XLSX.utils.json_to_sheet(flatData);

      XLSX.utils.book_append_sheet(wb, ws, "Team Summary");

      wb.SheetNames.unshift(wb.SheetNames.pop());
      wb.SheetNames.unshift(wb.SheetNames.pop());
      //make Overview the second sheet

      XLSX.writeFile(wb, name + ".xlsx");
    } else {
    }
    setExportCompetenciesLoading(false);
  };
  const exportToExcel = async (data, name) => {
    setExportImpedimentsLoading(true);
    const done = await exports.exportToExcel(name);
    setExportImpedimentsLoading(false);
  };

  const exportToExcelShort = async (data, name) => {
    setExportLoading(true);
    let allImpediments = [];
    //get impediments
    let impedimentList = await _teams.getTeamsAdminView(true, "impediments");

    if (impedimentList) {
      let filtered = impedimentList;
      let sortType = localStorage.getItem("Sort Type");
      let compList = impedimentList[0].competencies[0].questions;

      let hideIncomplete =
        localStorage.getItem("HideIncomplete") !== false ? true : false;
      hideIncomplete == true && setHideIncomplete(true);

      if (hideIncomplete) {
        filtered = filtered.filter((item) => {
          return item.assessment_status == "Finished";
        });
      }

      let hideUnclaimed = true;
      if (hideUnclaimed) {
        filtered = filtered.filter((item) => {
          return (
            item.TeamObj[0] &&
            item.TeamObj[0].Claimed &&
            item.TeamObj[0].Claimed == true
          );
        });
      }
      filtered = filtered && filtered.length > 0 && sortData(filtered);
      impedimentList = filtered;
      //sort impedimentList by team
      impedimentList.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });

      const wb = XLSX.utils.book_new();

      let flatData = [];
      impedimentList.forEach((item) => {
        let teamImpediemnts = [];
        let flatItem = {};
        flatItem.Team = item.Team;
        flatItem.Domain = item.TeamObj[0]?.Domain;
        flatItem["Total Impediments"] = item.impediments;
        flatItem.Burden = item.impedimentScores && item.impedimentScores.burden;
        flatItem.Effort = item.impedimentScores && item.impedimentScores.effort;
        flatItem.Maturity = item.scores?.avg_score.toFixed(2);
        flatItem["Maturity Level"] = item.scores?.maturity_level;
        item.scores?.scores_by_area_obj.forEach((score) => {
          flatItem[score.name] = score.score;
          // flatItem[score.name + " Burden"] = score.burden;
          // flatItem[score.name + " Effort"] = score.effort;
        });
        flatData.push(flatItem);

        // BEGIN: be15d9bcejpp
        item.impediment_list &&
          item.impediment_list.length > 0 &&
          item.impediment_list.forEach((impediment) => {
            let competency = impediment.Competency
              ? compList.filter((item) => item._id == impediment.Competency)
              : null;

            let area = competency && competency !== null && competency[0].Area;
            let competencyName = competency && competency[0].Name;
            let compKey = competency && competency[0].key;
            //get the score by filtering teams_impacted by claimed_by_team == item._id
            let teamImpediment = {};
            teamImpediment.Key = "IM" + impediment.Index.toString();
            teamImpediment.Team = item.Team;
            teamImpediment.Domain = item.TeamObj[0]?.Domain;
            teamImpediment["Competency Key"] = compKey;
            teamImpediment.Area = area;
            teamImpediment.Competency = competencyName;
            teamImpediment.Title = impediment.Title;
            teamImpediment.Description = impediment.Description;
            let claimedByObject = impediment.teams_impacted.filter(
              (team) => team.claimed_by_team == item._id
            );

            teamImpediment.Burden =
              claimedByObject &&
              claimedByObject.length > 0 &&
              claimedByObject[0].score;

            teamImpediment.Effort =
              claimedByObject && claimedByObject[0].effort;

            teamImpediment.Resolved =
              claimedByObject && claimedByObject[0].Resolved;
            teamImpediment.Escalated = item.Escalated;
            teamImpediment.Category = impediment.Category;
            teamImpediment.Subcategory = impediment.Subcategory;
            teamImpediment.Scope = impediment.Scope;
            // teamImpediment.resolved = impediment.Resolved;
            teamImpediemnts.push(teamImpediment);
            allImpediments.push(teamImpediment);
          });
        // END: be15d9bcejpp
        // before moving to the next item, create a new sheet with the impediments for this team
        // const ws1 = XLSX.utils.json_to_sheet(teamImpediemnts);
        //let sheetname truncate at 31 characters
        // let sheetname = item.Team;
        // if (sheetname.length > 31) {
        //   sheetname = sheetname.substring(0, 31);
        // }
        // XLSX.utils.book_append_sheet(wb, ws1, sheetname);
      });

      //deduplicate allImpediments, but add up the scores and the effort
      let flatDataDedupe = [];
      //sor allImpeidments by key
      allImpediments.sort(function (a, b) {
        if (a.Key && b.Key) {
          if (a.Key > b.Key) return 1;
          if (a.Key < b.Key) return -1;
        }
        return 0;
      });
      allImpediments.forEach((impediment) => {
        let existingImpediment = flatDataDedupe.find(
          (i) => i.Key == impediment.Key
        );
        if (existingImpediment) {
          existingImpediment.Burden += impediment.Burden;
          existingImpediment.Effort += impediment.Effort;
          existingImpediment["Reported By Count"] += 1;
          existingImpediment["Reported By"] += ", " + impediment.Team;
        } else {
          //delete impediment.Team and impediment.Domain
          impediment["Reported By Count"] = 1;
          impediment["Reported By"] = impediment.Team;
          delete impediment.Team;
          delete impediment.Domain;

          flatDataDedupe.push(impediment);
        }
      });

      const ws3 = XLSX.utils.json_to_sheet(flatDataDedupe);
      XLSX.utils.book_append_sheet(wb, ws3, "Short Impediments");

      // //create new sheet for all impediments
      // const ws2 = XLSX.utils.json_to_sheet(allImpediments);
      // XLSX.utils.book_append_sheet(wb, ws2, "All Impediments");

      // const ws = XLSX.utils.json_to_sheet(flatData);
      // //make this the first sheet
      // XLSX.utils.book_append_sheet(wb, ws, "Summary");
      // make "Summary" the first sheet
      wb.SheetNames.unshift(wb.SheetNames.pop());
      wb.SheetNames.unshift(wb.SheetNames.pop());

      XLSX.writeFile(wb, name + ".xlsx");
    } else {
    }
    setExportLoading(false);
  };

  //

  const buildExcelObject = (data) => {
    let teamImpediemnts = [];
    data.forEach((item) => {
      let questions =
        item.competencies &&
        item.competencies.length > 0 &&
        item.competencies[0]?.questions;

      questions &&
        questions.length > 0 &&
        questions.forEach((impediment) => {
          //get the score by filtering teams_impacted by claimed_by_team == item._id
          let teamImpediment = {};
          teamImpediment.Key = impediment.key;
          teamImpediment.Team = item.Team;
          teamImpediment.Domain = item.TeamObj[0]?.Domain;
          teamImpediment.Competency = impediment.Name;
          teamImpediment["DevOps Area"] = impediment.Area;
          teamImpediment.Maturity = impediment.score;

          teamImpediemnts.push(teamImpediment);
        });
    });
    return teamImpediemnts;
  };
  useEffect(() => {
    let filtered = initialValues;
    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        let filterId = filter.filter;
        let filterValue = filter.value;
        if (filter.filter == "hideIncomplete" && filter.value == true) {
          filtered = filtered.filter((item) => {
            return item.assessment_status == "Finished";
          });
        }
        if (filter.filter == "hideUnclaimed" && filter.value == true) {
          filtered = filtered.filter((item) => {
            return (
              item.TeamObj[0] &&
              item.TeamObj[0].Claimed &&
              item.TeamObj[0].Claimed == true
            );
          });
        }
      });

      filtered =
        filtered && filtered.length > 0 && sort.sortData(sortType, filtered);
    }
    setEmployees(filtered);
  }, [filters]);

  // FILTER FUNCTIONS
  // useEffect(() => {
  //   const newFilters = _filters.buildFilterObject(data, filterOptions);
  //   // const newTabs = _filters.buildTabs(data, newFilters);
  //   //
  //   setFilterOptions(newFilters);
  //   // setTabs(newTabs);
  // }, [defaultFilters]);

  // useEffect(() => {
  //   const newTabs = _filters.buildTabs(data, filterOptions);

  //   setTabsContainer(newTabs);
  // }, [data, filterOptions]);

  // useEffect(() => {
  //   setFilteredData(data);
  // }, [data]);

  // useEffect(() => {
  //   const newFilters = _filters.buildFilterObject(filteredData, defaultFilters);
  //   setFilterOptions(newFilters);
  //   // if (data && data.length > 0) {
  //   //   // {
  //   //   //   id: "category",
  //   //   //   name: "Category",
  //   //   //   options: [
  //   //   //     // { value: "tees", label: "Tees" },
  //   //   //     // { value: "crewnecks", label: "Crewnecks" },
  //   //   //     // { value: "hats", label: "Hats" },
  //   //   //   ],
  //   //   // },
  //   //   // {
  //   //   //   id: "services",
  //   //   //   name: "Services",
  //   //   //   options: [
  //   //   // { value: "clothing-company", label: "Clothing Company" },
  //   //   // { value: "fashion-inc", label: "Fashion Inc." },
  //   //   // { value: "shoes-n-more", label: "Shoes 'n More" },
  //   //   // ],
  //   //   // },
  //   //   let filters = [];

  //   //   FILTERS.forEach((filter) => {
  //   //     var filterObj = {};
  //   //     let filterId = filter.id;
  //   //     let filterName = filter.name;
  //   //     let filterOptions = [];
  //   //     filterObj.id = filterId;
  //   //     filterObj.name = filterName;
  //   //     data.forEach((item) => {
  //   //       if (item[filterId]) {
  //   //         if (Array.isArray(item[filterId])) {
  //   //           //check if filterOptions has an object with the same value
  //   //           item[filterId].forEach((option) => {
  //   //             if (!filterOptions.find((o) => o.value == option)) {
  //   //               filterOptions.push({ value: option, label: option });
  //   //             }
  //   //           });

  //   //           // item[filterId].forEach((option) => {
  //   //           //   if (!filterOptions.includes(option)) {
  //   //           //     filterOptions.push({ value: option, label: option });
  //   //           //   }
  //   //           // });
  //   //         } else {
  //   //           if (!filterOptions.find((o) => o.value == item[filterId])) {
  //   //             filterOptions.push({
  //   //               value: item[filterId],
  //   //               label: item[filterId],
  //   //             });
  //   //           }
  //   //         }
  //   //       }
  //   //     });
  //   //     filterObj.options = filterOptions;
  //   //     filters.push(filterObj);
  //   //   });
  //
  //   //   setFILTERS(filters);
  //   //   setFilterOptions(filters);
  //   // }
  // }, [filteredData]);

  // useEffect(() => {
  //   if (filters.length > 0) {
  //     let filtered = _filters.filter(data, filters);
  //     setFilteredData(filtered);
  //   } else {

  //     setFilteredData(data);
  //   }
  // }, [filters]);

  // useEffect(() => {

  //   if (selectedTab == "View All") {
  //     setFilteredData(data);
  //   } else {
  //     let filtered = data.filter((item) => item.services.includes(selectedTab));
  //     setFilteredData(filtered);
  //   }
  // }, [selectedTab]);

  // const handleChangeFilter = (filter, value) => {
  //   //if filter and value exist in filters, remove it.
  //   //if filter exists but value does not, add it
  //   //if filter does not exist, add it and add value
  //   //if filter exists but value does not, add it

  //   let newFilters = [...filters];
  //   let filterIndex = newFilters.findIndex((f) => f.filter == filter);
  //   if (filterIndex > -1) {
  //     let valueIndex = newFilters[filterIndex].value.findIndex(
  //       (v) => v == value
  //     );
  //     if (valueIndex > -1) {
  //       newFilters[filterIndex].value.splice(valueIndex, 1);
  //     } else {
  //       newFilters[filterIndex].value.push(value);
  //     }
  //   } else {
  //     newFilters.push({ filter: filter, value: [value] });
  //   }

  //   //if filter exists with no values, remove it
  //   newFilters = newFilters.filter((f) => f.value.length > 0);

  //   setFilters(newFilters);
  // };
  //END FILTER FUNCTIONS

  // useEffect(() => {
  //   let filtered;
  //   if (onlyShowGate === true) {
  //     filtered = employees.filter((item) => {

  //       return item.assessment_type === "gate";
  //     });
  //   } else {
  //     if (hideIncomplete === true) {
  //       filtered = list.filter((item) => {
  //         return (
  //           item.scores !== null &&
  //           item.scores !== undefined &&
  //           item.status == "Finished"
  //         );
  //       });
  //     } else {
  //       filtered = list;
  //     }
  //   }
  //   setEmployees(filtered);
  // }, [onlyShowGate]);

  useEffect(() => {
    let filtered = initialValues;
    if (hideIncomplete) {
      filtered =
        filtered &&
        filtered.length > 0 &&
        filtered.filter((item) => item.assessment_status == "Finished");
    }
    if (onlyShowGate) {
      filtered =
        filtered &&
        filtered.length > 0 &&
        filtered.filter((item) => {
          return item.assessment_type === "gate";
        });
    }
    // let hideUnclaimed = true;
    if (hideUnclaimed) {
      filtered =
        filtered &&
        filtered.length > 0 &&
        filtered.filter((item) => {
          return (
            item.TeamObj[0] &&
            item.TeamObj[0].Claimed &&
            item.TeamObj[0].Claimed == true
          );
        });
    }

    setEmployees(filtered);
  }, [hideIncomplete, onlyShowGate, hideUnclaimed]);

  useEffect(() => {
    let toBeSorted = [...employees];
    let sorted = sortData(toBeSorted);
    setEmployees(sorted);
  }, [sortType]);

  const sortData = (sortedList) => {
    // let sortedList = [...employees];

    if (sortType === "name-a-z") {
      sortedList.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });
      // return a.Team && b.Team && a.Team.toLowerCase() - b.Team.toLowerCase();

      // setEmployees(sortedList);
    } else if (sortType === "name-z-a") {
      sortedList.sort((a, b) => {
        if (a.Team && b.Team) {
          if (a.Team < b.Team) return 1;
          if (a.Team > b.Team) return -1;
        }
        return 0;
        // return b.Team && a.Team && b.Team.toLowerCase() - a.Team.toLowerCase();
      });
      // setEmployees(sortedList);
    } else if (sortType === "maturity-first-last") {
      sortedList.sort((a, b) => {
        if (a.scores && b.scores) {
          return b.scores.avg_score - a.scores.avg_score;
        }
        // return a.scores && b.scores && b.scores.avg_score - a.scores.avg_score;
      });
      // setEmployees(sortedList);
    } else if (sortType === "maturity-last-first") {
      sortedList.sort((a, b) => {
        return a.scores && b.scores && a.scores.avg_score - b.scores.avg_score;
      });
      // setEmployees(sortedList);
    }
    localStorage.setItem("Sort Type", sortType);

    return sortedList;
  };

  useEffect(() => {
    (async () => {
      let stats = await prepareData(employees);
      if (stats) {
        setAdminStatsData(stats);
      }
    })();
  }, [employees]);

  useEffect(() => {}, [statsData]);

  // useEffect(() => {

  //   localStorage.setItem("Team Table View", toggleValue);
  // }, [toggleValue]);

  const updateFilters = (filter) => {
    let newFilters = [...filters];
    let filterIndex = newFilters.findIndex((f) => f.filter == filter);
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
    } else {
      newFilters.push({ filter: filter, value: true });
    }
    setFilters(newFilters);
    //save filters to local storage
    localStorage.setItem("Filters", JSON.stringify(newFilters));
  };

  const handleHideIncomplete = () => {
    localStorage.setItem("HideIncomplete", !hideIncomplete);
    setHideIncomplete(!hideIncomplete);
  };
  const handleHideUnclaimedTeams = () => {
    localStorage.setItem("HideUnclaimed", !hideUnclaimed);
    setHideUnclaimed(!hideUnclaimed);
  };
  const handleSetCompactView = () => {
    localStorage.setItem("CompactView", !compactView);
    setCompactView(!compactView);
  };
  const handleOnlyShowGate = () => {
    setOnlyShowGate(!onlyShowGate);
  };

  const handleSetCompetencyModalOpen = (value, competencies) => {
    if (value == false || value == null || value == undefined) {
      setStatsData([]);
      setCompetencyModalOpen(false);
    } else {
      // setStatsData(null);
      setStatsData(competencies);
      setCompetencyModalOpen(true);
    }
  };

  const handleSort = (type) => {
    setSortType(type.value);
  };

  // useEffect(() => {
  //   (async () => {
  //     let compList = await _competencies.getCompetencyList();
  //     if (compList) {

  //       setCompetencies(compList);
  //     } else {

  //     }
  //   })();
  // }, [list]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true;
    setEmployees((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false;
  }, [employees]);

  const updateData = (updated, impedimentId, rowProps, fieldsToUpdate) => {
    //

    fieldsToUpdate.forEach((field) => {
      let rowIndex = field.rowIndex;
      let columnId = field.colName;
      let subfield;
      //if columnId has a period, split it up into an array
      if (columnId.includes(".")) {
        columnId = columnId.split(".");
        subfield = columnId[1];
        columnId = columnId[0];
      }

      let value = field.value;
      skipResetRef.current = true;
      setEmployees((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            if (subfield) {
              return {
                ...row,
                [columnId]: {
                  ...row[columnId],
                  [subfield]: value,
                },
              };
            } else {
              return {
                ...row,
                [columnId]: value,
              };
            }
          }

          return row;
        })
      );
    });

    // }
  };

  const updateImpediments = async () => {
    let updatedImpediments = await _impediments.getImpediments();
    if (updatedImpediments) {
      setEmployees(updatedImpediments);
      setInitialValues(updatedImpediments);
      setList(updatedImpediments);
      setLoading(false);
    }
  };

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       ID: "Team",
  //       Header: "Team",
  //       accessor: "Team",
  //       // accessorFn: (row) => `${row.Team} ${row.Claimed_by_team.name}`,
  //       Filter: SelectColumnFilter, // new
  //       filter: "includes", // new
  //       type: "text",
  //       // minWidth: "150px",
  //       // maxWidth: "150px",

  //       Cell: (props) => RenderTeamName(props),
  //     },
  //     // {
  //     //   id: "Nickname",
  //     //   Header: "Nickname",
  //     //   // Header: (props) =>
  //     //   //   RenderHeader(
  //     //   //     props,
  //     //   //     "Status",
  //     //   //     null,
  //     //   //     "Status is the current state of the impediment."
  //     //   //   ),
  //     //   accessor: "TeamObj[0].Nickname",

  //     //   Cell: (props) => RenderTextCell(props),
  //     //   // minWidth: "30px",
  //     //   // maxWidth: "30px",
  //     // },
  //     {
  //       id: "Maturity",
  //       Header: "Maturity",
  //       Header: (props) =>
  //         RenderHeader(
  //           props,
  //           "Maturity Level",
  //           null,
  //           "Click to see the maturity breakdown by DevOps Area."
  //         ),
  //       accessor: "scores.maturity_level",

  //       Cell: (props) =>
  //         RenderMaturityTags(
  //           props.row.original.scores &&
  //             props.row.original.scores.maturity_level,
  //           props.row.original.scores && props.row.original.scores.avg_score,
  //           handleSetCompetencyModalOpen,
  //           props.row.original
  //         ),
  //       // minWidth: "30px",
  //       // maxWidth: "30px",
  //     },

  //     {
  //       id: "impediments",
  //       Header: "Impediments",
  //       // Header: (props) =>
  //       //   RenderHeader(
  //       //     props,
  //       //     "Status",
  //       //     null,
  //       //     "Status is the current state of the impediment."
  //       //   ),
  //       accessor: "impediments",
  //       Cell: (props) =>
  //         RenderStat(props.row.original.impediments, "impediments"),
  //       // Cell: (props) => RenderTagCell(props),
  //       // minWidth: "30px",
  //       // maxWidth: "30px",
  //     },
  //     {
  //       id: "burden",

  //       Header: (props) =>
  //         RenderHeader(
  //           props,
  //           "Burden",
  //           "hrs/wk",
  //           "Burden should be expressed in hours per week wasted due to the impediment."
  //         ),
  //       accessor: "impedimentScores.burden",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores
  //             ? props.row.original.impedimentScores.burden
  //             : 0,
  //           "hrs/wk"
  //         ),
  //       // Cell: (props) =>
  //       //   RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
  //       // minWidth: "30px",
  //       // maxWidth: "30px",
  //     },

  //     {
  //       id: "Effort",
  //       Header: (props) =>
  //         RenderHeader(
  //           props,
  //           "Effort",
  //           "days",
  //           "Effort is expressed in days of work required to resolve the impediment."
  //         ),
  //       accessor: "impedimentScores.effort" || "0",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores
  //             ? props.row.original.impedimentScores.score
  //             : 0,
  //           "days"
  //         ),

  //       // Cell: (props) =>
  //       //   RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
  //       // minWidth: "30px",
  //       // maxWidth: "30px",
  //     },
  //     {
  //       id: "Potential",
  //       Header: (props) =>
  //         RenderHeader(
  //           props,
  //           "Potential",
  //           "days/yr",
  //           "Number of days per year that could be gained by clearing burden."
  //         ),
  //       accessor:
  //         "impedimentScores.summary.calculations.Future year savings (days)" ||
  //         "0",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores &&
  //             props.row.original.impedimentScores.summary &&
  //             props.row.original.impedimentScores.summary.calculations
  //             ? props.row.original.impedimentScores.summary.calculations[
  //                 "Future year savings (days)"
  //               ]
  //             : 0,
  //           "days/yr"
  //         ),
  //     },
  //     {
  //       id: "Realized",
  //       Header: (props) =>
  //         RenderHeader(
  //           props,
  //           "Realized Savings",
  //           "days/yr",
  //           "Number of days per year gained by clearing burden."
  //         ),
  //       accessor: "scores.gained" || "0",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores &&
  //             props.row.original.impedimentScores.summary &&
  //             props.row.original.impedimentScores.summary.calculations
  //             ? props.row.original.impedimentScores.summary.calculations[
  //                 "Realized Savings (days)"
  //               ]
  //             : 0,
  //           "days/yr"
  //         ),

  //       // Cell: (props) =>
  //       //   RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
  //       // minWidth: "30px",
  //       // maxWidth: "30px",
  //     },

  //     {
  //       id: "Resolved Impediments",
  //       Header: "Resolved Impediments",
  //       accessor: "impedimentScores.resolvedCount",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores
  //             ? props.row.original.impedimentScores.resolvedCount
  //             : 0,
  //           "impediments"
  //         ),
  //     },
  //     {
  //       id: "Resolved Burden",
  //       Header: "Resolved Burden",
  //       accessor: "impedimentScores.resolvedBurden",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores
  //             ? props.row.original.impedimentScores.resolvedBurden
  //             : 0,
  //           "hrs/wk"
  //         ),
  //     },
  //     {
  //       id: "Remaining Burden",
  //       Header: "Remaining Burden",
  //       accessor: "impedimentScores.remainingBurden",
  //       Cell: (props) =>
  //         RenderStat(
  //           props.row.original.impedimentScores
  //             ? props.row.original.impedimentScores.remainingBurden
  //             : 0,
  //           "hrs/wk"
  //         ),
  //     },

  //     // {
  //     //   ID: "Actions",
  //     //   Header: (props) => {
  //     //     let rendered = (
  //     //       <div className=" space-y-3">
  //     //         <div className="flex justify-start">
  //     //           <div className="text-slate-500">
  //     //             <svg
  //     //               xmlns="http://www.w3.org/2000/svg"
  //     //               fill="currentColor"
  //     //               className="w-4 h-4 "
  //     //               viewBox="0 0 20 20"
  //     //             >
  //     //               <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
  //     //             </svg>
  //     //           </div>
  //     //           <div className="ml-2 text-slate-500">Claim</div>
  //     //         </div>
  //     //         <div className="flex justify-start">
  //     //           <div className="text-slate-500">
  //     //             <svg
  //     //               xmlns="http://www.w3.org/2000/svg"
  //     //               fill="currentColor"
  //     //               className="flex w-5 h-5 "
  //     //               viewBox="0 0 20 20"
  //     //             >
  //     //               <path
  //     //                 fill-rule="evenodd"
  //     //                 d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
  //     //                 clip-rule="evenodd"
  //     //               />
  //     //             </svg>
  //     //           </div>
  //     //           <div className="ml-2 text-slate-500">Release</div>
  //     //         </div>
  //     //         <div className="flex justify-start">
  //     //           <div className="text-slate-500">
  //     //             <svg
  //     //               xmlns="http://www.w3.org/2000/svg"
  //     //               fill="none"
  //     //               viewBox="0 0 24 24"
  //     //               stroke-width="1.5"
  //     //               stroke="currentColor"
  //     //               className="w-5 h-5 "
  //     //             >
  //     //               <path
  //     //                 stroke-linecap="round"
  //     //                 stroke-linejoin="round"
  //     //                 d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
  //     //               />
  //     //             </svg>
  //     //           </div>
  //     //           <div className="ml-2 text-slate-500">Resolve</div>
  //     //         </div>
  //     //         <div className="flex justify-start">
  //     //           <div className="text-slate-500">
  //     //             <svg
  //     //               fill="currentColor"
  //     //               viewBox="0 0 20 20"
  //     //               className="w-4 h-4"
  //     //             >
  //     //               <path
  //     //                 fillRule="evenodd"
  //     //                 d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
  //     //                 clipRule="evenodd"
  //     //               ></path>
  //     //             </svg>
  //     //           </div>
  //     //           <div className="ml-2 text-slate-500">Details</div>
  //     //         </div>
  //     //       </div>
  //     //     );
  //     //     return RenderHeader(props, "Actions", null, rendered);
  //     //   },

  //     //   accessor: "Actions",
  //     //   Cell: (props) => (
  //     //     <div className="flex justify-start pr-6">
  //     //       {RenderActions(props, updateData, setUpdatedId)}
  //     //     </div>
  //     //   ),
  //     //   // Cell: (props) => RenderActions(props, updateData),
  //     //   type: "custom",
  //     //   minWidth: 10,
  //     //   maxWidth: 10,
  //     //   sticky: true,
  //     //   position: "left",
  //     // },
  //   ],
  //   []
  // );
  const prepareData2 = async (data) => {
    let preparedData = [];
    data.map((item) => {
      let t = item.TeamObj[0];
      let obj = {
        "Team Name": item.Team,
        "Maturity Level": item.scores ? item.scores.maturity_level : "None",
        "Maturity Score": item.scores ? item.scores.avg_score : 0,
        Impediments: item.impediments,
        "Reported Burden": item.impedimentScores
          ? item.impedimentScores.burden
          : 0,
        Effort: item.impedimentScores ? item.impedimentScores.effort : 0,

        "Resolved Impediments": item.impedimentScores
          ? item.impedimentScores.resolvedCount
          : 0,
        "Resolved Burden": item.impedimentScores
          ? item.impedimentScores.resolvedBurden
          : 0,
        "Remaining Burden": item.impedimentScores
          ? item.impedimentScores.remainingBurden
          : 0,
        "First year potential savings":
          item.impedimentScores &&
          item.impedimentScores.summary &&
          item.impedimentScores.summary.calculations
            ? item.impedimentScores.summary.calculations[
                "First year savings (days)"
              ]
            : 0,
        "Future year potential savings":
          item.impedimentScores &&
          item.impedimentScores.summary &&
          item.impedimentScores.summary.calculations
            ? item.impedimentScores.summary.calculations[
                "Future year savings (days)"
              ]
            : 0,

        summary:
          item.impedimentScores &&
          item.impedimentScores.summary &&
          item.impedimentScores.summary.calculations
            ? item.impedimentScores.summary.calculations
            : [],
        competencies: item.competencies[0] && item.competencies[0].questions,
      };
      preparedData.push(obj);
    });

    return preparedData;
  };

  const prepareData = async (data) => {
    let preparedData = [];
    data &&
      data.length > 0 &&
      data.map((item) => {
        let t = item.TeamObj[0];
        let obj = {
          _id: item._id,
          "Team Name": item.Team,
          Domain: item.TeamObj[0] && item.TeamObj[0].Domain,
          "Maturity Level": item.scores ? item.scores.maturity_level : "None",
          "Maturity Score": item.scores ? item.scores.avg_score : 0,
          Impediments: { value: item.impediments, measure: "impediments" },

          "Reported Burden": {
            value: item.impedimentScores ? item.impedimentScores.burden : 0,

            measure: "hrs/wk",
          },
          Effort: {
            value: item.impedimentScores ? item.impedimentScores.effort : 0,
            measure: "days",
          },

          "Resolved Impediments": {
            value: item.impedimentScores
              ? item.impedimentScores.resolvedCount
              : 0,
            measure: "impediments",
          },
          "Resolved Burden": {
            value: item.impedimentScores
              ? item.impedimentScores.resolvedBurden
              : 0,
            measure: "hrs/wk",
          },
          "Remaining Burden": {
            value: item.impedimentScores
              ? item.impedimentScores.remainingBurden
              : 0,
            measure: "hrs/wk",
          },
          "First year savings": {
            value:
              item.impedimentScores &&
              item.impedimentScores.summary &&
              item.impedimentScores.summary.calculations
                ? item.impedimentScores.summary.calculations[
                    "First year savings (days)"
                  ]
                : 0,
            measure: "days/yr",
          },
          "Future year savings": {
            value:
              item.impedimentScores &&
              item.impedimentScores.summary &&
              item.impedimentScores.summary.calculations
                ? item.impedimentScores.summary.calculations[
                    "Future year savings (days)"
                  ]
                : 0,
            measure: "days/yr",
          },
          // KTLO
          KTLO: { value: item.ktlo.length, measure: "activities" },
          "Reported KTLO Burden": {
            value: item.ktloScores ? item.ktloScores.burden : 0,

            measure: "hrs/wk",
          },
          "KTLO Effort": {
            value: item.ktloScores ? item.ktloScores.effort : 0,
            measure: "days",
          },

          "Resolved KTLO Activities": {
            value: item.ktloScores ? item.ktloScores.resolvedCount : 0,
            measure: "activities",
          },
          "Resolved KTLO Burden": {
            value: item.ktloScores ? item.ktloScores.resolvedBurden : 0,
            measure: "hrs/wk",
          },
          "Remaining KTLO Burden": {
            value: item.ktloScores ? item.ktloScores.remainingBurden : 0,
            measure: "hrs/wk",
          },
          "First year KTLO savings": {
            value:
              item.ktloScores &&
              item.ktloScores.summary &&
              item.ktloScores.summary.calculations
                ? item.ktloScores.summary.calculations[
                    "First year savings (days)"
                  ]
                : 0,
            measure: "days/yr",
          },
          "Future year KTLO savings": {
            value:
              item.ktloScores &&
              item.ktloScores.summary &&
              item.ktloScores.summary.calculations
                ? item.ktloScores.summary.calculations[
                    "Future year savings (days)"
                  ]
                : 0,
            measure: "days/yr",
          },
          //END KTLO

          summary: {
            value:
              item.impedimentScores &&
              item.impedimentScores.summary &&
              item.impedimentScores.summary.calculations
                ? item.impedimentScores.summary.calculations
                : [],

            measure: "",
          },
          competencies: {
            value: item.competencies[0] && item.competencies[0].questions,
            measure: "",
          },
        };
        preparedData.push(obj);
      });

    return preparedData;
  };

  // const onToggleChange = (value) => {
  //   if (value == false) {
  //     //list view
  //     setToggleValue(value);
  //     // const preparedData = prepareData(employees);
  //     // setAdminStatsData(preparedData);
  //     //
  //   } else {
  //     //table view
  //     setToggleValue(value);
  //     // setAdminStatsData([]);
  //   }
  // };

  const handleTextColumnValueChange = (e, id, fieldname) => {
    let newImpediments = [...employees];
    let index = newImpediments.findIndex((impediment) => impediment._id === id);
    //check if score field exists in employees state

    if (
      newImpediments &&
      newImpediments[index] &&
      fieldname in newImpediments[index]
    ) {
      newImpediments[index][fieldname] = e.target.value;
      setEmployees(newImpediments);
    }
  };

  const handleTextColumnValueChange2 = (e, id, fieldname, row) => {
    //update description field in state
    //setDescription with id, value

    //push updatedRow to updateField state
    // setUpdateField({ ...updateField, [id]: updatedRow });

    if (fieldname !== "Description") {
      let updatedRow = row;
      updatedRow[fieldname] = e.target.value;
      // ({ ...updateField, [id]: { [fieldname]: e.targsetUpdateFieldet.value } });
      setUpdateField({ ...updateField, [id]: updatedRow });
    } else {
      setDescription({ ...description, [id]: e.target.value });
    }
  };

  useEffect(() => {}, [updateField]);

  useEffect(() => {
    //loading changed
  }, [loading]);

  const handleOnBlur = async (e, id, fieldname) => {
    //get value from description state
    const value = e.target.value;
    //"description" ? description[id] : updateField[id][fieldname];

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      fieldname,
      // e.target.value
      value
    );

    if (update) {
      const newDescription = { ...description };
      delete newDescription[id];

      let newImpediments = [...employees];

      let index = newImpediments.findIndex(
        (impediment) => impediment._id === id
      );

      newImpediments[index][fieldname] = value;
      // newImpediments[index][fieldname] = value;
      // setEmployees(newImpediments);
    }
  };

  const RenderTeamColumn = (props) => {
    if (
      props.row.original.Claimed_by_team &&
      props.row.original.Claimed_by_team.name !== "JDO" &&
      props.row.original.Claimed_by_team !== ""
    ) {
      return (
        <div className="flex items-center space-x-3">
          <span>{props.row.original.Claimed_by_team.name}</span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-3">
          <span>{props.row.original.Team}</span>
        </div>
      );
    }
  };

  //get employees from api

  const viewDetails = (row, list) => {
    //
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const setChangedDateTime = () => {
    let date = new Date();
    let dateStr = date.toISOString();
    setChanged(dateStr);
    return dateStr;
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const getData = () => {
    const data = employees;

    return data;
  };

  return (
    <div className="w-full max-w-10xl space-y-2">
      <div className="pt-3 px-6  space-y-2 pb-2">
        <div className="sm:flex sm:justify-between items-center mb-6">
          <h2 className="text-xl flex-1 font-medium text-slate-800">Teams</h2>

          {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-start gap-2">
            <Toggle
              checked={hideIncomplete}
              onChange={handleHideIncomplete}
              label={"Hide Incomplete"}
            />
          </div> */}

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-6">
            {/* <Toggle
              checked={hideUnclaimed}
              onChange={handleHideUnclaimedTeams}
              label={"Hide Unclaimed Teams"}
            /> */}
            {/* <Toggle
              checked={onlyShowGate}
              onChange={handleOnlyShowGate}
              label={"Only show Gate assessment"}
            /> */}

            {/* <Toggle
              checked={
                filters &&
                filters.length > 0 &&
                filters.find((filter) => filter.id === "hideIncomplete")
                  ? true
                  : false
              }
              onChange={() => updateFilters("hideIncomplete")}
              label={"Hide Incomplete"}
            /> */}
            <Button
              loader={exportCompetenciesLoading}
              color="accent-text"
              handleClick={() => exportCompetenciesToExcel(employees, "Teams")}
              className="ml-4"
            >
              {exportCompetenciesLoading == false && (
                <ArrowDownCircleIcon className="h-5 w-5" />
              )}
              <span className="hidden xs:block ml-2">
                {" "}
                Export Competency Details
              </span>
            </Button>
            <Button
              loader={exportImpedimentsLoading}
              color="accent-text"
              handleClick={() => exportToExcel(employees, "Teams")}
              className="ml-2"
            >
              {exportImpedimentsLoading == false && (
                <ArrowDownCircleIcon className="h-5 w-5" />
              )}
              <span className="hidden xs:block ml-2">Export Impediments</span>
            </Button>
            <Button
              loader={exportLoading}
              color="accent-text"
              handleClick={() => exportToExcelShort(employees, "Teams")}
              className="ml-2"
            >
              {exportLoading == false && (
                <ArrowDownCircleIcon className="h-5 w-5" />
              )}
              <span className="hidden xs:block ml-2">
                Export Short Impediments
              </span>
            </Button>

            <Toggle
              checked={compactView}
              onChange={handleSetCompactView}
              label={"Compact View"}
            />

            <DropdownFilters
              buttonIcon={
                <ChevronUpDownIcon className="w-5 h-5" aria-hidden="true" />
              }
              handleOptionSelect={(option) => {
                handleSort(option);
              }}
              defaultValue={sortType}
              options={[
                {
                  name: "Team Name ",
                  value: "name-a-z",
                  icon: (
                    <Icon icon="tabler:sort-a-z" className="mr-3 h-6 w-6" />
                  ),
                },
                {
                  name: "Team Name",
                  value: "name-z-a",
                  icon: (
                    <Icon icon="tabler:sort-z-a" className="mr-3 h-6 w-6" />
                  ),
                },
                {
                  name: "Maturity",
                  value: "maturity-first-last",
                  icon: (
                    <Icon icon="tabler:sort-9-0" className="mr-3 h-6 w-6" />
                  ),
                },
                {
                  name: "Maturity",
                  value: "maturity-last-first",
                  icon: (
                    <Icon icon="tabler:sort-0-9" className="mr-3 h-6 w-6" />
                  ),
                },
              ]}
              buttonText={"Sort"}
              buttonProperties={{
                type: "outline",
              }}
            />
            {/* <ToggleButtons
              value={toggleValue}
              text1="List"
              text2="Table"
              onToggleChange={(value) => onToggleChange(value)}
            /> */}
            {/* <ToggleButtons
              value={toggleValue}
              onToggleChange={(value) => onToggleChange(value)}
            /> */}
            {/* <FeatureFlag featureName="Add Impediment" type="flag">
              <Button
                color="secondary"
                shadow={true}
                handleClick={() => setImpedimentModalOpen(true)}
                // className="btn bg-primary-500 hover:bg-primary-600 text-white"
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">New Impediment</span>
              </Button>
            </FeatureFlag> */}
            {/* <FeatureFlag featureName="Import Impediments" type="flag"> */}
            {/* <Button
                size="xs"
                color="primary"
                handleClick={(e) => {
                  
                }}
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">Import Impediments</span>
              </Button> */}
            {/* </FeatureFlag>
              <FeatureFlag featureName="Add Impediment" type="flag"> */}
            {/* <Button
                size="xs"
                color="secondary"
                handleClick={(e) => {
                  
                }}
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">Add Impediment</span>
              </Button> */}
            {/* </FeatureFlag> */}
          </div>
        </div>

        {adminStatsData == "loading" || loading == true ? (
          <Skeleton type="spinner" />
        ) : (
          <>
            <Tip color="accent" title="Click on a team to view details">
              Note: If a team is not listed, they have not yet completed the
              assessment.
            </Tip>

            <AdminStats
              data={adminStatsData}
              rawData={employees}
              handleSetCompetencyModalOpen={handleSetCompetencyModalOpen}
              compactView={compactView}
            />
          </>
        )}
      </div>
      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={"Impediments"}
        showClose={true}
        showFooter={true}
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        {/* <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={null}
          competencies={competencies}
          modalOpen={impedimentModalOpen}
          questions={impedimentModalOpen && []}
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={updateImpediments} //{refreshQuestions}
          setQuestions={updateImpediments} //{setQuestions}
          modalItem={null}
          title={"Impediment "}
          description={"Add a new impediment below"}
          create={true}
        /> */}
      </ModalBasic>
      <ModalBasic
        id="modal-competency-scores"
        modalOpen={competencyModalOpen}
        setModalOpen={handleSetCompetencyModalOpen}
        title={"Competency Scores"}
        showClose={true}
        showFooter={true}
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <Stats
          data={statsData}
          process={true}
          loading={false}
          title=""
          open={competencyModalOpen}
          shadow={false}
        />
      </ModalBasic>
    </div>
  );
}

export default Teams;
