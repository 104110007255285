import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import Badge from "./Badge";
import Skeleton from "./Skeleton";
import { tailwindConfig } from "../utils/Utils";
import StepsBullets from "./StepsBullets";
import Button from "./Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminStats(props) {
  const history = useHistory();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState();
  const [data, setData] = useState();
  const [stepData, setStepData] = useState();
  const [compactView, setCompactView] = useState(false);
  const [rawData, setRawData] = useState();
  // const data = props.data;

  // useEffect(() => {
  //   if (props.open === false) {
  //     setStats(null);

  //   }
  // }, [props.open]);
  useEffect(() => {
    setCompactView(props.compactView);
  }, [props.compactView]);
  useEffect(() => {
    console.log("AdminStats: ", props.data);
    setStats([]);
    let thisData = props.data;

    if (
      thisData !== "loading" &&
      thisData !== undefined &&
      thisData !== null &&
      thisData.length > 0 &&
      Array.isArray(thisData)
    ) {
      //
      setData(data);
      setStats(thisData);
      //   processData(thisData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.rawData) {
      setRawData(props.rawData);
    }
  }, [props.rawData]);

  const goToActionPlan = (comps, scores, id) => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        test: comps,
        data: comps,
        tenantId: id,
        thisAssessment: { questions: comps, scores: scores },
      },
    });
  };

  const getStepOptions = (data) => {
    let stepOptions = [];
    // setLoading(true);
    if (data == "Beginner") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "current" },
        { name: "Intermediate", href: "#", status: "upcoming" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Intermediate") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "current" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Advanced") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "current" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Expert") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "complete" },
        { name: "Expert", href: "#", status: "current" },
      ];
    }

    return stepOptions;
  };

  const getLevelName = (score) => {
    if (score < 2) {
      return "Beginner";
    } else if (score <= 3) {
      return "Intermediate";
    } else if (score < 4) {
      return "Advanced";
    } else if (score <= 5) {
      return "Expert";
    }
    return "None";
  };
  const handleClick = (e, value) => {
    e.preventDefault();

    props.handleSetCompetencyModalOpen(true, value);
  };

  const getLevelColor = (score) => {
    if (score < 2) {
      //beginner
      return "text-red-700";
    } else if (score < 3) {
      //intermediate
      return "text-orange-500";
    } else if (score < 4) {
      //advanced
      return "text-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "text-emerald-500";
    } else {
      //default
      return "text-slate-600";
    }
  };

  const getLevelBackgroundColor = (score) => {
    if (score <= 2) {
      //beginner
      return "bg-red-700";
    } else if (score <= 3) {
      //intermediate
      return "bg-orange-500";
    } else if (score < 4) {
      //advanced
      return "bg-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "bg-emerald-500";
    } else {
      //default
      return "bg-slate-600";
    }
  };

  return (
    <div className="flex flex-col w-full space-y-0 gap-0 ">
      {stats.map((items, index) => {
        let comps = items["competencies"].value;
        let maturity = items["Maturity Level"];
        let score = items["Maturity Score"];
        let scores = {
          maturity_level: maturity,
          avg_score: score,
        };
        let id = items._id;

        return (
          // <div className="sm:flex sm:justify-between sm:items-center ">
          // <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
          //   Teams <span className="text-slate-400 font-medium"></span>
          // </h1>

          // {/* Right: Actions */}
          // <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

          // </div>
          //   </div>

          <div className="col-span-10 bg-white space-y-0 gap-0 border border-gray-200">
            {/* Left Items */}
            <header className=" grid grid-flow-col align-middle px-5 py-4">
              <div className="flex items-center align-middle  text-lg font-semibold text-slate-800 2 ml-0">
                <span className="mr-2">{index + 1}</span>
                <UserGroupIcon className="flex h-6 w-6 mr-2 fill-accent-600 " />{" "}
                {items["Team Name"]}
                <span className="flex rounded border border-cyan-700 bg-cyan-500 bg-opacity-20 px-2 mx-4 uppercase text-xs font-medium text-cyan-700">
                  {items["Domain"] ? items["Domain"] : "No Domain"}
                </span>
                <span className="flex rounded border border-yellow-700 bg-yellow-100 text-yellow-700 px-2 mx-1 uppercase text-xs font-medium ">
                  {items["KTLO"] &&
                  items["KTLO"].value &&
                  items["KTLO"].value > 0
                    ? `KTLO: ${items["KTLO"].value} tasks`
                    : "No KTLO"}
                </span>
                {items["Remaining KTLO Burden"] &&
                items["Remaining KTLO Burden"].value &&
                items["Remaining KTLO Burden"].value > 0 ? (
                  <span className="flex rounded border border-yellow-700 bg-yellow-100 text-yellow-700 px-2 mx-0 uppercase text-xs font-medium ">
                    Remaining KTLO Burden:{" "}
                    {items["Remaining KTLO Burden"].value}{" "}
                    {items["Remaining KTLO Burden"].measure}
                  </span>
                ) : null}
                {/* <span className=""></span> */}
              </div>
              {/* Right Items */}
              <div className="grid grid-flow-col space-x-6 items-center sm:auto-cols-max justify-start sm:justify-end gap-2">
                {items["Maturity Level"] === "None" || items === undefined ? (
                  <div className="flex items-center align-middle  text-lg font-semibold text-slate-800 2 ml-0">
                    No Assessment history
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        " inline-block text-lg font-bold  " +
                        getLevelColor(items["Maturity Score"])
                      }
                    >
                      {items["Maturity Level"]}
                    </div>
                    <div className="inline-block">
                      <StepsBullets
                        steps={() => getStepOptions(items["Maturity Level"])}
                      />
                    </div>
                    <div
                      className={
                        "text-lg  font-semibold " +
                        getLevelColor(items["Maturity Score"])
                      }
                    >
                      {items["Maturity Score"]
                        ? items["Maturity Score"].toFixed(2)
                        : 0}
                    </div>
                    <div className="space-x-3">
                      {/* <Button
                        rounded
                        disabled={
                          items["Maturity Level"] === "None" ||
                          items === undefined
                        }
                        size="xs"
                        handleClick={(e) => {
                          handleClick(e, items.competencies.value);
                        }}
                        color="accent"
                        //   className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      >
                        Score Details
                      </Button> */}
                      <Button
                        rounded
                        size="xs"
                        color="accent"
                        handleClick={(e) => {
                          e.preventDefault();
                          goToActionPlan(comps, scores, id);
                        }}
                      >
                        Action Plan
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </header>
            {!compactView && (
              <dl className="mt- p-2 grid grid-cols-4 md:grid-cols-8 divide-y  divide-gray-200   md:divide-y-0 md:divide-x">
                {Object.entries(items).map(([key, v], index) => {
                  let label = key;
                  let value =
                    items && items[key] && items[key].value
                      ? items[key].value
                      : items[key];
                  let measure =
                    items && items[key] && items[key].measure
                      ? items[key].measure
                      : null;

                  if (
                    key !== "Team Name" &&
                    key !== "Domain" &&
                    key !== "Maturity Level" &&
                    key !== "Maturity Score" &&
                    key !== "summary" &&
                    key !== "competencies" &&
                    key !== "_id"
                  ) {
                    return (
                      <div className="flex flex-col items-center justify-between">
                        <header className="p-6">
                          <h3 className="text-sm h-12 font-semibold text-slate-500 uppercase mb-1">
                            {key}
                          </h3>
                          <div className="relative text-2xl font-bold text-slate-800 mb-1">
                            {/* use toFixed(2) only if value has decimals */}
                            {items[key].value && items[key].value % 1 != 0
                              ? items[key].value.toFixed(2)
                              : items[key].value}
                          </div>
                          <div className="text-sm text-slate-500">
                            <span>{measure}</span>
                          </div>
                        </header>
                      </div>
                    );
                  }
                })}
              </dl>
            )}
          </div>
        );
      })}
    </div>
  );
}
