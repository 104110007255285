import { Fragment, useEffect, useState } from "react";
// import { utils } from "../../../modules/_utils";
import Button from "./Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "./QuestionToolTip";
// import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "./Badge";
import { _teams } from "../modules/_teams";
import Pivot from "./Pivot";
const XLSX = require("xlsx");

export default function CompareTable({
  data,
  solicitation,
  handleSelectWinnerClick,
  handleNotifyVendorClick,
  modalOpen,
}) {
  // const [allResponses, setResponses] = useState(data);
  const [allResponses, setResponses] = useState(data);
  // const [solicitation, setSolicitation] = useState(props.solicitation);
  const [allLineItems, setAllLineItems] = useState([]);
  const [allPricingItems, setAllPricingItems] = useState([]);
  const [colWidth, setColWidth] = useState("w-1/3");
  const [teamsByImpediment, setTeamsByImpediment] = useState([]);

  // useEffect(() => {

  //   setResponses(data);
  //   if (data && data.length > 0) {
  //     setColWidth(`w-1/4`);
  //     // setColWidth(`w-${Math.floor(100 / data.length)}`);
  //   }
  // }, [data]);
  useEffect(() => {
    (async () => {
      if (modalOpen) {
        // setExportLoading(true);
        let allImpediments = [];
        let impedimentsByTeam = [];
        //get impediments
        let impedimentList = await _teams.getTeamsAdminView(
          true,
          "impediments"
        );

        if (impedimentList) {
          let filtered = impedimentList;
          let sortType = localStorage.getItem("Sort Type");

          let hideUnclaimed = true;
          if (hideUnclaimed) {
            filtered = filtered.filter((item) => {
              return (
                item.TeamObj[0] &&
                item.TeamObj[0].Claimed &&
                item.TeamObj[0].Claimed == true
              );
            });
          }
          // filtered = filtered && filtered.length > 0 && sortData(filtered);
          impedimentList = filtered;
          //sort impedimentList by team
          impedimentList.sort(function (a, b) {
            if (a.Team && b.Team) {
              if (a.Team > b.Team) return 1;
              if (a.Team < b.Team) return -1;
            }
            return 0;
          });

          let flatData = [];
          impedimentList.forEach((item) => {
            let teamImpediemnts = [];
            let flatItem = {};
            flatItem.Team = item.Team;
            flatItem.Domain = item.TeamObj[0]?.Domain;
            flatItem["Total Impediments"] = item.impediments;
            flatItem.Burden =
              item.impedimentScores && item.impedimentScores.burden;
            flatItem.Effort =
              item.impedimentScores && item.impedimentScores.effort;
            flatItem.Maturity = item.scores?.avg_score.toFixed(2);
            flatItem["Maturity Level"] = item.scores?.maturity_level;
            item.scores?.scores_by_area_obj.forEach((score) => {
              flatItem[score.name] = score.score;
              // flatItem[score.name + " Burden"] = score.burden;
              // flatItem[score.name + " Effort"] = score.effort;
            });
            flatData.push(flatItem);

            // BEGIN: be15d9bcejpp
            item.impediment_list &&
              item.impediment_list.length > 0 &&
              item.impediment_list.forEach((impediment) => {
                //get the score by filtering teams_impacted by claimed_by_team == item._id
                let teamImpediment = {};
                teamImpediment.Key = "IM" + impediment.Index.toString();
                teamImpediment.Team = item.Team;
                teamImpediment.Domain = item.TeamObj[0]?.Domain;
                teamImpediment.Title = impediment.Title;
                teamImpediment.Description = impediment.Description;
                let claimedByObject = impediment.teams_impacted.filter(
                  (team) => team.claimed_by_team == item._id
                );

                teamImpediment.Burden =
                  claimedByObject &&
                  claimedByObject.length > 0 &&
                  claimedByObject[0].score;

                teamImpediment.Effort =
                  claimedByObject && claimedByObject[0].effort;

                teamImpediment.Resolved =
                  claimedByObject && claimedByObject[0].Resolved;
                teamImpediment.Escalated = item.Escalated;
                teamImpediment.Category = impediment.Category;
                teamImpediment.Subcategory = impediment.Subcategory;
                teamImpediment.Scope = impediment.Scope;
                // teamImpediment.resolved = impediment.Resolved;
                teamImpediemnts.push(teamImpediment);
                allImpediments.push(teamImpediment);
              });
            impedimentsByTeam.push(teamImpediemnts);

            // END: be15d9bcejpp
            // before moving to the next item, create a new sheet with the impediments for this team
          });

          setResponses(allImpediments);
        } else {
        }
      }
    })();
  }, [modalOpen]);

  useEffect(() => {
    //output should be {key:123, title:"my impediment", teams: [{team: "team1", burden: 1, effort: 2, resolved: true}, {team: "team2", burden: 1, effort: 2, resolved: true}]}
    //sample input is [
    // {Key: 'IM2110', Team: 'ADS', Domain: 'Automation Services', Title: 'Custom Pages causes frequent deployment failures', Description: 'There is significant count of tasks is present on the dashboard', …}

    // {Key: 'IM2040', Team: 'ADS', Domain: 'Automation Services', Title: 'Existing code is not well structured - hard to figure out what the code is doing', Description: 'Code is not well structured - hard to figure out what the code is doing', …}
    // ]
    let impedimentsByTeam = [];
    allResponses.sort(function (a, b) {
      if (a.Key && b.Key) {
        if (a.Key > b.Key) return 1;
        if (a.Key < b.Key) return -1;
      }
      return 0;
    });

    allResponses.map((impediment) => {
      //check IMpedimentsByTeam to see if this impediment exists.  If not, add it. If so, add the team to the teams array
      let teamImpediment = impedimentsByTeam.filter((item) => {
        return item.Key == impediment.Key;
      });
      if (teamImpediment && teamImpediment.length > 0) {
        //add the team to the teams array
        teamImpediment[0].teams.push({
          team: impediment.Team,
          burden: impediment.Burden,
          effort: impediment.Effort,
          resolved: impediment.Resolved,
          domain: impediment.Domain,
        });
      } else {
        //add the impediment to the array
        impedimentsByTeam.push({
          Key: impediment.Key,
          Title: impediment.Title,
          teams: [
            {
              team: impediment.Team,
              burden: impediment.Burden,
              effort: impediment.Effort,
              resolved: impediment.Resolved,
              domain: impediment.Domain,
            },
          ],
        });
      }
    });

    setTeamsByImpediment(impedimentsByTeam);
  }, [allResponses]);

  const generateRows = () => {
    const rows = [];
    return <th scope="col">Service Name</th>;
  };

  return (
    <div
      style={{ height: "calc(100vh - 105px)" }}
      className="col-span-full  overflow-x-auto overflow-y-scroll
      scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full

    "
    >
      <Pivot data={allResponses}></Pivot>
      {/* <table
        id="response_table"
        className="table-auto overflow-x-scroll w-full text-sm text-left text-gray-500 dark:text-gray-400"
      >
        <thead class="sticky  z-10 top-0 w-full text-xs text-gray-700  bg-gray-50  dark:text-gray-400">
          <tr>
            <th
              scope="col"
              class={`sticky left-0 h-fit  px-6 py-3  bg-gray-50  border-r border-gray-200`}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
            ></th>

            <th
              scope="col"
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",

                // Don't forget this, required for the stickiness
              }}
              class={`w-96 px-2  py-3 whitespace-nowrap text-xl px-6 py-3 `}
            >
              Impediment
            </th>
            <th
              scope="col"
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",

                // Don't forget this, required for the stickiness
              }}
              class={`w-96 px-2  py-3 whitespace-nowrap text-xl px-6 py-3 `}
            >
              Total
            </th>
          </tr>
        </thead>
        {teamsByImpediment &&
          teamsByImpediment.length > 0 &&
          teamsByImpediment.map((impediment, idx) => {
            return (
              <>
                <tr
                  colSpan={teamsByImpediment?.length + 1}
                  className="  bg-accent-500/30 relative  py-2 uppercase text-sm leading-6 text-gray-900"
                >
                  <th
                    // scope="col"
                    colSpan={3}
                    // class={`sticky left-0 h-fit  px-6 py-3`}
                    style={
                      {
                        // width: "300px",
                        // maxWidth: "300px",
                        // minWidth: "300px",
                      }
                    }
                    class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="text-sm font-semibold text-slate-800 mr-3 mb-2 ml-0">
                        {`${impediment.Key} - ${impediment.Title}`}
                      </div>
                      <div className="text-sm font-semibold text-slate-800 mr-3 mb-2 ml-0">
                      
                        {impediment.teams &&
                          impediment.teams.length > 0 &&
                          impediment.teams.reduce((a, b) => a + b.burden, 0)}
                      </div>
                    </div>
                  </th>
                </tr>
                {impediment.teams &&
                  impediment.teams.length > 0 &&
                  impediment.teams.map((team) => {
                    return (
                      <tr class="pl-10 py-6 bg-white border-b  dark:border-gray-700">
                   

                        <td className="pl-12 text-gray-600 font-normal ">
                          <div className="space-y-2">
                            <div className="font-semibold text-slate-800"></div>
                          </div>
                        </td>
                        <td className="text-gray-600 font-normal ">
                          <div className="space-y-2">
                            <div className="font-semibold text-slate-800">
                              {team.team}{" "}
                              <span className="text-xs text-slate-500 uppercase">
                                ({team.domain})
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-gray-600 font-normal ">
                          <div className="space-y-2">
                            <div className="font-semibold text-slate-800">
                              {team.burden}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            );
          })}
      </table> */}
    </div>
  );
}
