import React, { useState, useEffect, useRef } from "react";
import {
  KeyIcon,
  UserCircleIcon,
  CreditCardIcon,
  UserGroupIcon,
  PlusIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";
import Uploader from "./Uppy";
// import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CalendarIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import { qList } from "../modules/_assessment_questions";
import Badge from "./Badge";
import Button from "./Button";
import { _assessment } from "../modules/_assessment";
import MultiSelectListBox from "./MultiSelectListBox";
import RadioGroupCards from "./RadioGroupCards";
import DialogBox from "./Dialog";
import { _impediments } from "../modules/impediments";
import Toggle from "./Toggle";
import { RenderCompetencyList } from "../pages/Impediments";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Tip from "./Tip";
import AutosuggestDropdown from "./AcssessmentTemplatesQuestCardDropdown";
import Paragraph from "./Paragraph";
import { _auth } from "../modules/_auth";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let options = [
  {
    id: 1,
    value: "Infrastructure",
    name: "Infrastructure",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 1,
    value: "Architecture",
    name: "Architecture",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 1,
    value: "Other",
    name: "Other",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 2,
    value: "Insufficient Tooling",
    name: "Insufficient Tooling",
    area: "Operational",
    description: "",
    Examples: [],
  },
  {
    id: 3,
    value: "Process",
    name: "Process",
    area: "Procedural",
    description: "",
    Examples: [],
  },
  {
    id: 4,
    value: "Org Chart",
    name: "Org Chart",
    area: "Organizational",
    description: "",
    Examples: [],
  },
];

export default function AssessmentTemplateQuestionCard(props) {
  // const [question, setQuestion] = useState(props.question);
  // const [questions, setQuestions] = useState(props.question);
  const [pageType, setPageType] = useState(
    props.type === "impediment" ? "impediments" : "ktlo"
  );
  const [updated, setUpdated] = useState({});
  const [modified, setModified] = useState(false);
  // const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const [toggleValue, setToggleValue] = useState(false);
  const [autoSuggestResults, setAutoSuggestResults] = useState([]);
  const [autoSuggestLoading, setAutoSuggestLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showAutoSuggest, setShowAutoSuggest] = useState(false);
  // const [question, setQuestion] = useState({});
  const question = props.question;
  const index = props.index;
  const create = props.create ? props.create : false;
  const createQuestion = props.createQuestion ? props.createQuestion : false;
  const competencies = props.competencies ? props.competencies : [];
  const [activeOption, setActiveOption] = useState({});
  // const [claimed, setClaimed] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const profile = _auth.getUserProfile();
  let timer;
  let autoSuggestTimer;
  // useEffect(() => {
  //   (async () => {})();
  // }, []);

  useEffect(() => {
    setPageType(props.type === "impediment" ? "impediments" : "ktlo");
  }, [props.type]);

  useEffect(() => {
    (async () => {
      let data;
      if (autoSuggestTimer) {
        clearTimeout(autoSuggestTimer);
      }

      if (searchValue.length < 3) {
        setAutoSuggestResults([]);
        setShowAutoSuggest(false);
        setActiveOption({});
        return;
      }

      if (autoSuggestTimer < 300) {
        return;
      }
      // setClaimed(false);

      data = await _impediments.searchImpediments(searchValue, pageType);
      if (data) {
        autoSuggestTimer = setTimeout(() => {
          data && data.length > 0 && setAutoSuggestResults(data);
          setActiveOption(data[0]);
          setShowAutoSuggest(true);
        }, 300);
      } else {
        autoSuggestTimer = setTimeout(() => {
          setActiveOption({});
          setAutoSuggestResults([]);
          showAutoSuggest(false);
        }, 300);
      }
    })();
  }, [searchValue]);

  // useEffect(() => {

  //   activeOption &&
  //     activeOption.teams_impacted &&
  //     activeOption.teams_impacted.findIndex((x) => {
  //       if (x.claimed_by_team == profile.tenantId) {

  //         // setClaimed(true);
  //       } else {

  //         // setClaimed(false);
  //       }
  //     });
  // }, [activeOption]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !showAutoSuggest ||
        dropdown.current.contains(target)
        // ||
        // trigger.current.contains(target)
      )
        return;
      setShowAutoSuggest(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!showAutoSuggest || keyCode !== 27) return;
      setShowAutoSuggest(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {}, [props.competencies]);

  useEffect(() => {
    setUpdated(props.question);
    // setQuestion(props.question);
  }, [props.question]);

  useEffect(() => {
    if (JSON.stringify(question) != JSON.stringify(updated)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [updated]);

  useEffect(() => {
    console.log("UPDATED CHANGED", updated, pageType);
  }, [updated]);

  useEffect(() => {
    if (deleteConfirmed == true) {
    }
  }, [deleteConfirmed]);

  const handleDeleteQuestionClick = (e, id) => {
    setConfirmBox(true);
    setDeletionEvent(e);
    setQuestionIdToDelete(id);
  };

  const deleteQuestion = async () => {
    // setDeleteConfirmed(true);
    deletionEvent.preventDefault();
    const deleted =
      props.type == "impediment"
        ? await _impediments.deleteImpediment(questionIdToDelete, pageType)
        : props.type == "ktlo"
        ? await _impediments.deleteImpediment(questionIdToDelete, pageType)
        : await _assessment.deleteQuestion(questionIdToDelete);

    if (deleted) {
      props.removeQuestion(index);
      setDeleteConfirmed(false);
      setSuccess(true);
      setDeletionEvent(null);
      setQuestionIdToDelete(null);
      setConfirmBox(false);
    }
  };

  const handleSelect = (value, name) => {
    //
    let options = [];
    if (name == "type") {
      if (value == "Multiple Choice") {
        options = [null, "", "", ""];
      }
    }
    if (name == "best_answer") {
      if (type == "True/False") {
        options = [true, false];
      }

      if (type == "Yes/No") {
        options = ["Yes", "No", "Unsure"];
      }
    }

    props.updateQuestion(props.index, {
      answer_options: options,
    });

    setUpdated({
      ...updated,
      [name]: value,
      answer_options: options,
    });
  };

  const handleToggleChange = (value, name) => {
    name = "is_team_specific";
    value = !toggleValue;
    setToggleValue(value);
    setUpdated({
      ...updated,
      [name]: value,
    });
  };
  const handleSelectImpedimentType = (value, name) => {
    setUpdated({
      ...updated,
      [name]: value,
    });
  };

  const handleImpedimentSelect = async (id, impediment, value, objValue) => {
    let subcategory = objValue.name;

    setUpdated({
      ...updated,
      Subcategory: subcategory,
    });
  };

  const handleCompetecySelect = async (id, competecy, value, objValue) => {
    let competency = objValue.name;
    let area = objValue.area;
  };

  const handleFieldChange = (e, q) => {
    //

    let { name, value, type, f } = e.target;
    if (name == "Title") {
      setSearchValue(value);
    }
    // save updated question in updated state
    setUpdated({
      ...updated,
      [name]: value,
    });
  };

  const handleUpdateQuestionButtonClick = async () => {
    setLoading(true);
    let newData =
      props.type == "impediment"
        ? await _impediments.updateImpedimentOnCreationScreen(updated, pageType)
        : props.type == "ktlo"
        ? await _impediments.updateImpedimentOnCreationScreen(updated, pageType)
        : await _assessment.updateTemplateQuestions(updated, question._id);
    // let newData = await _assessment.updateTemplateQuestions(
    //   updated,
    //   question._id
    // );
    if (newData) {
      // setQuestion(newData);
      setTimeout(() => {
        setModified(false);
        setLoading(false);
        props.replaceQuestion(newData);
      }, 1000);
    } else {
    }

    // setLoading(false);
  };

  const handleSaveQuestionButtonClick = async () => {
    console.log("Saving question", updated);
    setLoading(true);

    let newData =
      props.type == "impediment"
        ? await _impediments.createImpediment(updated, pageType)
        : props.type == "ktlo"
        ? await _impediments.createImpediment(updated, pageType)
        : await _assessment.addNewQuestion(updated);
    if (newData) {
      // setQuestion(newData);
      setTimeout(() => {
        setModified(false);
        setLoading(false);
        if (props.type == "impediment" || props.type == "ktlo") {
          //setUpdated with newData
          setUpdated(newData);
          props.updateQuestion(null, newData);
          props.updateCompetencies(newData);
        } else {
          //update question with _id from new data
          //update updated with new data
          setUpdated(newData);
          props.replaceQuestion(newData);
        }
      }, 2000);
    } else {
    }

    // setLoading(false);
  };

  const isClaimed = (activeOption) => {
    let claimed;
    if (activeOption && activeOption.teams_impacted) {
      claimed = activeOption.teams_impacted.findIndex((x) => {
        return x.claimed_by_team == profile.tenantId;
      });
      if (claimed >= 0) {
        claimed = true;
      } else {
        claimed = false;
      }
    } else {
      claimed = false;
    }

    return claimed;
  };

  const handleClaimImpediment = async () => {
    let claimed = isClaimed(activeOption);

    let success = await _impediments.plusOneImpediment(
      activeOption._id,
      claimed == true ? false : true,
      pageType
    );
    if (success) {
      if (claimed == true) {
        //remove team from teams_impacted

        let teams_impacted = activeOption.teams_impacted.filter((x) => {
          return x.claimed_by_team != profile.tenantId;
        });

        //set activeOption teams_impacted to new teams_impacted
        setActiveOption({
          ...activeOption,
          teams_impacted: teams_impacted,
        });
      } else {
        //add team to teams_impacted

        let teams_impacted = [
          ...activeOption.teams_impacted,
          {
            claimed_by_team: profile.tenantId,
            effort: 0,
            score: 0,
            claimed_by_person: profile.userId,
            Resolved: false,
          },
        ];

        //set activeOption teams_impacted to new teams_impacted
        setActiveOption({
          ...activeOption,
          teams_impacted: teams_impacted,
        });
        //update autoSuggestResults with new teams_impacted
        let newAutoSuggestResults = autoSuggestResults.map((x) => {
          if (x._id == activeOption._id) {
            return {
              ...x,
              teams_impacted: teams_impacted,
            };
          } else {
            return x;
          }
        });
        setAutoSuggestResults(newAutoSuggestResults);
      }
    }

    //update ActiveOption with new claimed status
  };

  const handleDiscardQuestionButtonClick = async () => {
    setDiscardLoading(true);
    setTimeout(() => {
      setModified(false);
      setDiscardLoading(false);
      if (props.type == "impediment") {
        props.removeImpediment(index);
      } else {
        props.removeQuestion(index);
      }
    }, 300);
  };
  const prevBestAnswer = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevBestAnswer.current = updated.best_answer;
  }, [updated]);

  const handleOptionsFieldChange = (e, q, oldAnswer, index) => {
    let { name, value, type, f } = e.target;
    let best_answer;

    if (name == "best_answer") {
      console.info(
        "Prev best:",
        prevBestAnswer.current,
        "Answer opt",
        updated.answer_options[0],
        "value",
        value
      );
      index = updated.answer_options.findIndex(
        (a) => a == prevBestAnswer.current
      );

      best_answer = value;
    }

    if (
      name == "answer_options" &&
      updated.best_answer == prevBestAnswer.current
    ) {
      best_answer = value;
    }

    let answer_options = updated.answer_options.map((a, i) => {
      if (i == index) {
        return value;
      } else {
        return a;
      }
    });

    // saveUpdated answer_options index of oldAnswer
    setUpdated({
      ...updated,
      //update answer_options
      answer_options: answer_options,

      best_answer: best_answer,
    });
  };

  const handleListMouseOver = (e, result) => {
    //delay execution of setActiveOption to allow user to move mouse over list

    timer = setTimeout(() => {
      setActiveOption(result);
    }, 300);
  };

  const type =
    updated && updated.type
      ? updated.type
      : question.type
      ? question.type
      : null;

  return (
    <>
      {/* <form action={null}> */}
      {props.type !== "impediment" && props.type !== "ktlo" ? (
        <div className="shadow space-y-6 mb-6 sm:overflow-hidden sm:rounded-md">
          <div className=" bg-white py-6 px-4 sm:p-6">
            <div className="grid grid-cols-3 border-b mb-6 py-1 space-x-6">
              <h3 className="text-lg font-medium leading-6 text-slate-900 ">
                {props.type !== "impediment" ? "Question" : "Impediment"}{" "}
                {index + 1}
              </h3>
              {/* <div className="inline col-start-4 justify-items-end">
                <Badge> {createQuestion && "Create Question"}</Badge>
              </div>
              <div className="inline col-start-5 justify-items-end">
                <Badge> {create && "Create"}</Badge>
              </div> */}

              <div className="inline-flex col-start-4 justify-items-end">
                {updated.type !== null && (
                  <Badge color={"accent"}> {updated.type}</Badge>
                )}
              </div>
              {question._id && (
                <div className="inline-flex  col-start-6 justify-items-end">
                  <Button
                    iconButton
                    color={"white"}
                    handleClick={(e) =>
                      handleDeleteQuestionClick(e, question._id)
                    }
                  >
                    <TrashIcon
                      className={"h-5 w-5 text-slate-500 hover:text-red-500"}
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Question
                </label>
                <textarea
                  type="text"
                  name={`question`}
                  id={`${question._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.question}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
              </div>
              {createQuestion && (
                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Question Type
                  </label>
                  <div className=" text-sm">
                    <RadioGroupCards
                      name="type"
                      defaultValue={question.type}
                      value={updated.type}
                      onChange={(e) => handleSelect(e, "type")}
                      options={[
                        {
                          id: 1,
                          title: "Multiple Choice",
                          description:
                            "This question will have multiple options to choose from.",
                          value: "Multiple Choice",
                        },
                        {
                          id: 2,
                          title: "Yes/No",
                          description:
                            "This question will have the options of Yes or No.",
                          value: "Yes/No",
                        },
                        {
                          id: 3,
                          title: "True/False",
                          description:
                            "This question will have the options of True or False.",
                          value: "True/False",
                        },
                      ]}
                    />
                  </div>
                  {/* <MultiSelectListBox
                    options={["Multiple Choice", "Yes/No", "True/False"]}
                    fieldType="single"
                    label="Question Type"
                    name="type"
                    handleSelect={(e, value) => handleSelect(e, value)}
                    // handleDeselect={(e) => handleDeselect(e)}
                    defaultValue={"Multiple Choice"}
                    value={type}
                  /> */}
                </div>
              )}

              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Best Answer
                </label>
                {type == "Yes/No" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="Yes">Yes</option>
                  //   <option value="No">No</option>
                  //   <option value="Unsure">Unsure</option>
                  // </select>
                  // <div className=" text-sm">
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "Yes",
                        description: "",
                        value: "Yes",
                      },
                      {
                        id: 2,
                        title: "No",
                        description: "",
                        value: "No",
                      },
                      {
                        id: 3,
                        title: "Unsure",
                        description: "",
                        value: "Unsure",
                      },
                    ]}
                  />
                )}
                {type == "True/False" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="true">True</option>
                  //   <option value="false">False</option>
                  // </select>
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "True",
                        description: "",
                        value: true,
                      },
                      {
                        id: 2,
                        title: "False",
                        description: "",
                        value: false,
                      },
                    ]}
                  />
                )}
                {type == "Multiple Choice" && (
                  <textarea
                    type="text"
                    name="best_answer"
                    // id={`${q_iduestions.}`}
                    onChange={(e) =>
                      handleOptionsFieldChange(
                        e,
                        question,
                        question.best_answer,
                        index
                      )
                    }
                    defaultValue={question.best_answer}
                    // disabled={create == true ? false : true}
                    // autoComplete="family-name"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                )}
              </div>
              {question.answer_options &&
                question.answer_options.map((answer, index) => {
                  // figure out how to show only 3 options + best answer
                  if (
                    ((question.best_answer == "" ||
                      question.best_answer == null) &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False") ||
                    (answer !== "" &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False")
                  ) {
                    return (
                      <div key={index} className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Answer Option {index}
                        </label>

                        <textarea
                          type="text"
                          rows={5}
                          // name={`${questions._id}`}
                          // id={`${questions._id}`}
                          onChange={(e) =>
                            handleOptionsFieldChange(e, question, answer, index)
                          }
                          defaultValue={answer}
                          // disabled={create == true ? false : true}
                          // autoComplete="family-name"
                          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                        />
                      </div>
                    );
                  }
                })}
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Action Plan/Explanation
                </label>
                <textarea
                  rows={5}
                  type="text"
                  name="action_plan"
                  // id={`${questions._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.action_plan}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
                <div className="flex space-x-6 justify-end pt-10">
                  {!updated._id && (
                    <Button
                      loader={discardLoading}
                      handleClick={() => handleDiscardQuestionButtonClick()}
                      color={"white"}
                      audit={false}
                    >
                      Discard
                    </Button>
                  )}
                  <Button
                    loader={loading}
                    disabled={!modified}
                    handleClick={
                      !updated._id
                        ? () => handleSaveQuestionButtonClick()
                        : () => handleUpdateQuestionButtonClick()
                    }
                    color={"lime"}
                    audit={false}
                  >
                    {!updated._id ? (loading ? "Saving" : "Save") : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {confirmBox && (
            <DialogBox
              handleConfirmClick={deleteQuestion}
              loading={loading}
              success={success}
            />
          )}
        </div>
      ) : (
        <div className="shadow space-y-6 mb-6 sm:overflow-hidden sm:rounded-md">
          <div className=" bg-white py-6 px-4 sm:p-6">
            <div className="grid grid-cols-3 border-b mb-6 py-1 space-x-6">
              <h3 className="text-lg font-medium leading-6 text-slate-900 ">
                {props.pageType === "impediment"
                  ? " Impediment"
                  : "KTLO Activity "}
                {index + 1}
              </h3>

              <div className="inline-flex col-start-4 justify-items-end">
                {updated.type !== null && <Badge> {updated.type}</Badge>}
              </div>
              {question._id && (
                <div className="inline-flex  col-start-6 justify-items-end">
                  <Button
                    iconButton
                    color={"white"}
                    handleClick={(e) =>
                      handleDeleteQuestionClick(e, question._id)
                    }
                  >
                    <TrashIcon
                      className={"h-5 w-5 text-slate-500 hover:text-red-500"}
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-6 gap-6">
              {/* Competency */}
              {props.pageType === "impediment" && (
                <div className="col-span-4  ">
                  <label
                    htmlFor="name"
                    className="col-span-4 block text-sm font-medium text-slate-700"
                  >
                    What competency does this impede? (required)
                  </label>
                  <span className="col-span-4 text-sm text-gray-500">
                    To best help you, it is important that we know what
                    competency this impediment is related to. If you are unsure,
                    close this screen and browse through the competencies until
                    you find the one that it blocks.
                  </span>
                  <div className="col-span-3 py-3">
                    {question.Competency && question.Competency.name ? (
                      <div className="flex items-center">
                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                          {/* get competency from competencies by id*/}
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">
                          {question.Competency.name}
                        </span>
                      </div>
                    ) : (
                      <RenderCompetencyList
                        from={props.from}
                        row={question}
                        competencies={props.competencies}
                        updateData={(e) => {
                          handleFieldChange(e, question);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* Title */}
              <div className="col-span-4 ">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Title
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name={`Title`}
                    id={`${question._id}`}
                    onChange={(e) => handleFieldChange(e, question)}
                    defaultValue={question.description}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />

                  <div className="absolute inset-y-0 right-3 flex items-center">
                    {showAutoSuggest && (
                      <XMarkIcon
                        className="cursor-pointer inline h-5 w-5 text-gray-400"
                        onClick={(e) => {
                          setSearchValue("");
                          setUpdated({
                            ...updated,
                            Title: "",
                          });
                          setUpdated({
                            ...updated,
                            Description: "",
                          });
                          setShowAutoSuggest(false);
                          setAutoSuggestResults([]);
                        }}
                      />
                    )}
                  </div>
                </div>

                {showAutoSuggest && (
                  <Transition
                    show={showAutoSuggest}
                    tag="div"
                    className="z-10 max-h-100 top-full left-0 w-full bg-white border border-gray-200 py-3 rounded shadow-lg  mt-1"
                    enter="transition ease-out duration-100 transform"
                    enterStart="opacity-0 -translate-y-2"
                    enterEnd="opacity-100 translate-y-0"
                    leave="transition ease-out duration-100"
                    leaveStart="opacity-100"
                    leaveEnd="opacity-0"
                  >
                    {
                      <div
                        ref={dropdown}
                        className="font-medium text-sm text-slate-600"
                        // onFocus={() => setShowAutoSuggest(true)}
                        // onBlur={() => setShowAutoSuggest(false)}
                      >
                        {" "}
                        <ul
                          tabIndex={-1}
                          role="listbox"
                          aria-labelledby="listbox-label"
                          aria-activedescendant="listbox-item-3"
                          // className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          className="flex  focus:outline-none sm:text-sm"
                        >
                          <div
                            className="flex flex-col max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
          scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
                          >
                            {" "}
                            {autoSuggestResults &&
                            autoSuggestResults.length > 0 ? (
                              <>
                                <div className="border-b mx-3 pb-2">
                                  <h2 className="flex flex-row mb-2 mt-2 text-xs font-semibold text-gray-500">
                                    POSSIBLE MATCHES
                                  </h2>

                                  <Tip className="" color="accent">
                                    If someone else has already created a
                                    similar item, please select it from the list
                                    below. If not, please continue creating a
                                    new item.
                                  </Tip>
                                </div>
                              </>
                            ) : (
                              <h2 className="flex flex-row mx-3 mb-2 mt-2 text-xs font-semibold text-gray-500">
                                NO MATCHES FOUND, CONTINUE CREATING NEW ITEM
                              </h2>
                            )}
                            {autoSuggestResults.map((result, resultIdx) => (
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 space-y-3 cursor-pointer hover:bg-gray-50
                                bg-${
                                  activeOption &&
                                  activeOption._id &&
                                  result &&
                                  result._id &&
                                  result._id == activeOption._id
                                    ? "gray-100"
                                    : "white"
                                }
                                `}
                                key={result.id}
                                // onMouseEnter={() => {
                                //   setActiveOption(result);
                                // }}
                                onMouseOver={(e) => {
                                  handleListMouseOver(e, result);
                                }}
                                onMouseOut={() => {
                                  clearTimeout(timer);
                                }}
                                onClick={() => setActiveOption(result)}
                              >
                                <div className="flex justify-between">
                                  <div id="list" className=" items-center ">
                                    <div className=" font-normal  font-semibold">
                                      IM-{result.Index} - {result.Title}
                                    </div>
                                    <div className="font-normal   text-gray-500 ">
                                      {result.Description}
                                    </div>
                                  </div>
                                  {/* <div>
                                    <PlusIcon
                                      className=" h-5 w-5 text-green-500"
                                      aria-hidden="true"
                                    />
                                  </div> */}
                                </div>
                              </li>
                            ))}
                          </div>
                          {activeOption &&
                            Object.keys(activeOption).length > 0 &&
                            autoSuggestResults &&
                            autoSuggestResults.length > 0 && (
                              <div className=" w-1/2 flex-none flex-col border-l border-gray-200 divide-y divide-gray-100 overflow-y-auto sm:flex">
                                <div className="flex flex-auto flex-col justify-between px-4">
                                  <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                    <div className="flex justify-between">
                                      <dt>IM-{activeOption.Index}</dt>
                                      {activeOption.Competency.length > 0 && (
                                        <dd className="flex items-center bg-accent-600 bg-opacity-50 px-1 text-accent-800 rounded text-xs">
                                          <span>
                                            {activeOption.Competency[0].key +
                                              "  "}{" "}
                                            &nbsp;&nbsp;
                                            {activeOption.Competency[0].Name}
                                          </span>
                                        </dd>
                                      )}
                                    </div>

                                    <h2 className="text-base font-semibold">
                                      {activeOption.Title}
                                    </h2>
                                    <div className="font-normal   text-gray-500 ">
                                      {activeOption.Description}
                                    </div>
                                  </dl>
                                  <Button
                                    color={
                                      isClaimed(activeOption)
                                        ? "gray"
                                        : "secondary"
                                    }
                                    // disabled={claimed ? true : false}
                                    size="small"
                                    handleClick={() => {
                                      handleClaimImpediment();
                                    }}
                                    // type="button"
                                    // className="mt-6 w-full rounded-md bg-secondary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-600"
                                  >
                                    {isClaimed(activeOption)
                                      ? "Remove from my team"
                                      : "Add to my team"}
                                  </Button>
                                </div>
                              </div>
                            )}
                        </ul>
                        <div className="pt-3 mt-4 border-t border-gray-200 text-center">
                          <Button
                            color="link"
                            handleClick={(e) => {
                              setSearchValue("");
                              setUpdated({
                                ...updated,
                                Title: "",
                              });
                              setUpdated({
                                ...updated,
                                Description: "",
                              });
                              setShowAutoSuggest(false);
                              setAutoSuggestResults([]);
                            }}
                          >
                            Create a new item with this title
                          </Button>
                        </div>
                      </div>
                    }
                  </Transition>
                )}
              </div>

              {/* Description */}
              <div className="col-span-full ">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Description
                </label>
                <textarea
                  type="text"
                  name={`Description`}
                  id={`${question._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.description}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
              </div>
              {/* Type */}
              <div className="col-span-3 space-y-3 ">
                <Toggle
                  checked={toggleValue}
                  onChange={handleToggleChange}
                  label={"This is specific to my team."}
                />
                <Tip className="" color="accent">
                  Use this option with caution. If you select this option, this
                  item will only be visible to your team. If you do not select
                  this option, this item will be visible to all teams and will
                  help us identify common items across the organization.
                </Tip>
              </div>
              {/* Score and Burden */}
              <div className=" items-center col-span-6 gap-6 sm:grid sm:grid-cols-6  sm:space-y-0 space-y-6">
                <div className="col-span-3 sm:col-span-full   ">
                  {/* <div className="flex col-span-1"> */}
                  <label
                    htmlFor="name"
                    className="mr-6 block text-sm font-medium text-slate-700"
                  >
                    How many hours do you or other teams spend per week doing
                    manual work because of item?
                  </label>
                  <span className="text-sm text-gray-500">
                    We use this information to help show you how much time
                    you'll get back by resolving or automating. Give it your
                    best guess. You can always change it later.
                  </span>
                  <input
                    type="text"
                    name="score"
                    // id={`${questions._id}`}
                    onChange={(e) => handleFieldChange(e, question)}
                    defaultValue={question.score}
                    className="mt-1 block w-48 rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />

                  {/* </div> */}
                  {/* <div className="flex col-span-1"> */}
                </div>
                <div className="col-span-3 sm:col-span-full  ">
                  <label
                    htmlFor="name"
                    className="mr-6 block text-sm font-medium text-slate-700"
                  >
                    What is the effort required to resolve this?
                  </label>
                  <span className="text-sm text-gray-500">
                    We use this information to help show you how much time
                    you'll get back by resolving or automating. Give it your
                    best guess. You can always change it later.
                  </span>
                  <input
                    type="text"
                    name="effort"
                    // id={`${questions._id}`}
                    onChange={(e) => handleFieldChange(e, question)}
                    defaultValue={question.effort}
                    className="mt-1 block w-48  rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* {createQuestion && (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor="Category"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Impediment Type
                  </label>
                  <div className=" text-sm">
                    <RadioGroupCards
                      name="Category"
                      className={"mt-4 grid  gap-y-6 grid-cols-4  sm:gap-x-4"}
                      defaultValue={question.Category}
                      value={updated.Category}
                      onChange={(e) =>
                        handleSelectImpedimentType(e, "Category")
                      }
                      options={[
                        {
                          id: 1,
                          title: "Technical",
                          description:
                            "This imepediment is due to architecture, infrastructure, or other technical issues.",
                          value: "Technical",
                        },
                        {
                          id: 2,
                          title: "Operational",
                          description:
                            "This impediment is due to  tooling, frameworks, provisioning pipelines, environment stability, or other operational components.",
                          value: "Operational",
                        },
                        {
                          id: 3,
                          title: "Procedural",
                          description:
                            "This impediment is due to processes, procedures, policies, or other procedural issues that require attention.",
                          value: "Procedural",
                        },
                        {
                          id: 3,
                          title: "Organizational",
                          description:
                            "This impediment is related to culture, organization structure, team structure or other organization-related issues.",
                          value: "Organizational",
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              <div className="col-span-6 sm:col-span-2">
                <div className="mt-4">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-slate-700"
                  >
                    {type} Subcategory
                  </label>
                  <div className=" text-sm">
                    <MultiSelectListBox
                      options={options.filter((option) => {
                        return option.area === updated.Category;
                      })}
                      fieldType="single"
                      label="Question Type"
                      name="Subcategory"
                      handleSelect={handleImpedimentSelect}
                      // handleDeselect={(e) => handleDeselect(e)}
                      defaultValue={updated.Subcategory}
                      value={
                        updated.Subcategory
                          ? updated.Subcategory
                          : "Select an option"
                      }
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-span-6 sm:col-span-6 pb-12">
                {" "}
                <div className="flex space-x-6 justify-end pt-10">
                  {!updated._id && (
                    <Button
                      loader={discardLoading}
                      handleClick={() => handleDiscardQuestionButtonClick()}
                      color={"white"}
                      audit={false}
                    >
                      Discard
                    </Button>
                  )}
                  <Button
                    loader={loading}
                    disabled={!modified}
                    handleClick={
                      !updated._id
                        ? () => handleSaveQuestionButtonClick()
                        : () => handleUpdateQuestionButtonClick()
                    }
                    color={"secondary"}
                    audit={false}
                  >
                    {!updated._id ? (loading ? "Saving" : "Save") : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {confirmBox && (
            <DialogBox
              handleConfirmClick={deleteQuestion}
              loading={loading}
              success={success}
            />
          )}
        </div>
      )}

      {/* </form> */}
    </>
    //   </div>
    // </div>
  );
}
