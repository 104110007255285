import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _impediments = {
  // two ways to get impediments
  // 1. get all impediments for a company
  // 2. get all impediments for a team

  getImpedimentTypeList(pageType) {
    let options = [
      {
        id: 1,
        value: "Infrastructure",
        name: "Infrastructure",
        area: "Technical",
        description: "",
        Examples: [],
      },
      {
        id: 1,
        value: "Architecture",
        name: "Architecture",
        area: "Technical",
        description: "",
        Examples: [],
      },
      {
        id: 1,
        value: "Other",
        name: "Other",
        area: "Technical",

        description: "",
        Examples: [],
      },
      {
        id: 2,
        value: "Insufficient Tooling",
        name: "Insufficient Tooling",
        area: "Operational",
        description: "",
        Examples: [],
      },
      {
        id: 3,
        value: "Process",
        name: "Process",
        area: "Procedural",
        description: "",
        Examples: [],
      },
      {
        id: 4,
        value: "Org Chart",
        name: "Org Chart",
        area: "Organizational",
        description: "",
        Examples: [],
      },
    ];
    return options;
  },

  async getImpediments(scope, pageType) {
    let profile = _auth.getUserProfile();
    //how do we know which one to get?
    // if the user is a company admin, get all impediments for the company
    console.log("Getting ", pageType, " tasks", scope);
    let data;
    if (scope == true) {
      data = {
        tenantId: null,
        tenantType: profile.tenantInfo.type,
        companyId: profile.companyId,
        pageType: pageType,
      };
    } else {
      data = {
        tenantType: profile.tenantInfo.type,
        tenantId: profile.tenantId,
        companyId: profile.companyId,
        pageType: pageType,
      };
    }

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/impediments/get", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async createImpediment(data, pageType) {
    let profile = _auth.getUserProfile();
    //if data.Compentency is an object, set data.Competency = data.Competency.id
    if (data.Competency && data.Competency.id) {
      data.Competency = data.Competency.id;
    }

    // try {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.claimed_by_team = profile.tenantId;
    data.claimed_by_person = profile.user_id;
    let body = {
      updates: data,
      tenantId: profile.tenantId,
      pageType: pageType,
    };

    let response = await postRequest("/impediments/create", body);

    if (response && response.data) {
      return response.data.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },
  async deleteImpediment(id, pageType) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        companyId: profile.companyId,
        pageType: pageType,
        ids: [id],
        id: id,
      };

      let response = await postRequest("/impediments/delete", data);

      if (response && response.data) {
        return response;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async updateImpediment(
    impedimentId,
    competencyId,
    fieldname,
    value,
    otherData,
    pageType,
    from
  ) {
    let profile = _auth.getUserProfile();
    console.log("updateImpediment from", from, pageType);
    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        ids: [impedimentId],
        updates: {},
        competencyId: competencyId,
        pageType: pageType,
      };
      data.updates[fieldname] = value;
      if (otherData) {
        data.updates = { ...data.updates, ...otherData };
      }

      let response = await postRequest("/impediments/update", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async updateImpedimentOnCreationScreen(updated, pageType, from) {
    let profile = _auth.getUserProfile();
    console.log("updateImpediment from", from, pageType);
    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        ids: [updated._id],
        updates: {},

        pageType: pageType,
      };
      // data.updates[fieldname] = value;
      if (updated) {
        data.updates = { ...data.updates, ...updated };
      }

      let response = await postRequest("/impediments/update", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async updateImpedimentCompetencyId(impedimentId, competencyId, pageType) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId,
        impedimentId: impedimentId,
        competencyId: competencyId,
        pageType: pageType,
      };

      let response = await postRequest(
        "/impediments/update-competency-id",
        data
      );

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async claimImpediment(impedimentId, claimed, pageType) {
    try {
      let profile = _auth.getUserProfile();
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        tenantId: profile.tenantId,
        // teamId: profile.teamId ? profile.teamId : profile.tenantId,
        pageType: pageType,
        ids: [impedimentId],
        updates: {
          Claimed_by_team: claimed ? profile.tenantId : null,
          Claimed_by_person: profile.user_id,
          Claimed: claimed,
        },
      };

      let response = await postRequest("/impediments/claim", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async plusOneImpediment(impedimentId, claimed, pageType) {
    // try {
    let profile = _auth.getUserProfile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let claimedby = claimed ? profile.tenantId : null;

    let data = {
      tenantId: profile.tenantId,
      // teamId: profile.teamId ? profile.teamId : profile.tenantId,
      pageType: pageType,
      ids: [impedimentId],
      updates: {
        // teams_impacted: [
        // {
        claimed_by_team: profile.tenantId,
        claimed_by_person: profile.user_id,
      },
      // ],
      // },
    };

    let response = await postRequest("/impediments/plusone", data);

    if (response && response.data) {
      return response.data.data.data;
    } else {
      return [];
    }
    // }
    // catch (e) {
    //   return [];
    // }
  },
  async resolveImpediment(impedimentId, resolved, pageType) {
    // try {
    let profile = _auth.getUserProfile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    let data = {
      tenantId: profile.tenantId,
      ids: [impedimentId],
      pageType: pageType,
      updates: {
        Resolved: resolved,
        resolvedBy: profile.tenantId,
        resoveDate: Date.now(),
      },
    };

    let response = await postRequest("/impediments/resolve", data);

    if (response && response.data) {
      return response.data.data.data;
    } else {
      return [];
    }
    // }
    // catch (e) {
    //   return [];
    // }
  },
  async getResolvedAndRemainingBurden(admin, pageType) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        tenantId: profile.tenantId,
        company_id: profile.companyId,
        admin: admin,
        pageType: pageType,
      };

      let response = await postRequest(
        "/dashboard/get-resolved-and-remaining-burden",
        data
      );

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getImpedimentSummary(pageType) {
    let profile = _auth.getUserProfile();

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        companyId: profile.companyId,
        pageType: pageType,
      };

      let response = await postRequest(
        "/impediments/get-impediment-summary",
        data
      );

      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async searchImpediments(query, pageType) {
    let profile = _auth.getUserProfile();

    // try {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let data = {
      companyId: profile.companyId,
      query: query,
      pageType: pageType,
    };

    let response = await postRequest("/impediments/autocomplete", data);

    if (response && response.data) {
      return response.data.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },
};
